import React, { FC } from "react";
import { Chart } from "react-chartjs-2";
import { ChartLoading } from "@esgian/esgianui";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  ChartTypeRegistry,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  Plugin,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";

import { ChartData } from "../../types/charts";

type Prop = {
  plugins?: Plugin<keyof ChartTypeRegistry, unknown>[]; // Ensure the plugins are typed correctly
  id?: string;
  loading?: boolean;
  data?: ChartData;
  options?: ChartOptions;
  type?: "line" | "bar";
  chartRef: React.RefObject<ChartJS | undefined>; // Reference to Chart.js instance
};

ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  BarElement,
  BarController,
  CategoryScale,
  TimeScale,
  LinearScale,
  Filler,
  Tooltip,
);
ChartJS.defaults.font.family = [
  "Noto Sans",
  "Roboto",
  "helvetica",
  "Arial",
  "sans-serif",
].join(",");

const ChartJs: FC<Prop> = ({
  plugins = [],
  chartRef = undefined,
  id = undefined,
  loading = false,
  data = {
    datasets: [],
    labels: [],
  }, // Fallback to empty data structure
  options = {},
  type = "line",
  ...props
}) => {
  if (loading || !options) {
    return <ChartLoading />;
  }

  return (
    <Chart
      plugins={plugins}
      id={id}
      ref={chartRef}
      type={type}
      data={data} // Ensure data is always a valid ChartData object
      options={options}
      {...props}
    />
  );
};

export default ChartJs;
