import { Chart } from "chart.js";

import { getNumberWithMetricPrefix } from "../../../helper/numbers";
import { FONT_FAMILY, GenericType } from "../../../types";
import { ChartTimeSeriesDataSet } from "../../../types/charts";

export const getOrCreateTooltipDiv = (
  chart: Chart,
  palette: GenericType,
): HTMLElement => {
  const {
    tooltip: { background, contrastText },
  } = palette;
  let tooltipEl = chart.canvas.parentNode?.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.opacity = "1";
    tooltipEl.style.visibility = "visible";
    tooltipEl.style.fontSize = "14px";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.overflowWrap = "nowrap";
    tooltipEl.style.padding = "8px 16px";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const body = document.createElement("div");
    body.style.display = "flex";
    body.style.flexDirection = "column";

    tooltipEl.appendChild(body);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }
  tooltipEl.style.background = background;

  tooltipEl.style.color = contrastText;

  return tooltipEl;
};

export const createTooltipLabelText = (labelText: string): HTMLElement => {
  const label = document.createElement("span");
  label.textContent = `${labelText}:`;
  label.style.fontFamily = FONT_FAMILY;
  label.style.fontSize = "14px";
  label.style.lineHeight = "1.66";
  return label;
};
export const createTooltipValueText = (textValue: string): HTMLElement => {
  const text = document.createElement("p");
  text.textContent = textValue;
  text.style.fontFamily = FONT_FAMILY;
  text.style.fontSize = "14px";
  text.style.margin = "0";
  text.style.lineHeight = "1.43";
  return text;
};

export const getTooltipBodyValueDisplay = (
  {
    label,
    borderColor,
    type,
    hidden,
    backgroundColor,
    unit: hiddenUnit,
  }: ChartTimeSeriesDataSet,
  value: string | number,
  unit?: string,
) => {
  const content = document.createElement("div");
  content.style.display = "flex";
  content.style.justifyContent = "space-between";
  content.style.gap = "32px";

  const legend = document.createElement("div");
  legend.style.display = "flex";
  legend.style.gap = "8px";
  legend.style.alignItems = "center";
  legend.style.flexDirection = "row";

  const legendBox = document.createElement("span");
  const bgColor = type === "bar" ? backgroundColor : borderColor;
  legendBox.style.background = bgColor ?? "";
  legendBox.style.height = type === "bar" ? "8px" : "2px";
  legendBox.style.width = "8px";
  legendBox.style.display = "inline-block";

  const title = createTooltipLabelText(label);

  let valueText;
  const displayUnit = unit ?? "";

  if (value !== null) {
    let v = value as number;
    if (typeof value === "string") {
      v = parseFloat(value);
    }
    const valWithPrefix = getNumberWithMetricPrefix(v);
    valueText = createTooltipValueText(
      `${valWithPrefix} ${hidden ? hiddenUnit : displayUnit}`,
    );
  } else {
    valueText = createTooltipValueText("-");
  }
  legend.appendChild(legendBox);
  legend.appendChild(title);
  content.appendChild(legend);
  content.appendChild(valueText);

  return content;
};
export const createTooltipHeader = (): HTMLElement => {
  const header = document.createElement("div");
  header.style.fontWeight = "600";
  header.style.fontSize = "16px";
  header.style.fontFamily = FONT_FAMILY;
  header.style.fontStyle = "normal";
  header.style.fontWeight = "600";
  header.style.lineHeight = "24px";
  header.style.letterSpacing = "0.15px";
  return header;
};
