import { useState } from "react";
import { Box, Button, Stack, Typography } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { PowerOutputCompareAdvancedProject } from "../../components/PowerOutputCompareAdvancedProject";
import { PowerOutputCompareProject } from "../../components/PowerOutputCompareProject";
import { PowerOutputHeader } from "../../components/PowerOutputHeader";
import { PowerOutputHistoricalCapacityAreaChart } from "../../components/PowerOutputHistoricalCapacityAreaChart";
import { PowerOutputKeyBoxProject } from "../../components/PowerOutputKeyBoxProject";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import {
  getPowerOutputPeriodType,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { GenericType } from "../../types";

export const PowerOutputProfile = () => {
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedPeriodType = useSelector(getPowerOutputPeriodType);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const [selectedTab, setSelectedTab] = useState("output");
  const { powerDataQuery } = usePowerGenQueries();

  return (
    <Box sx={{ px: 2, py: 2 }}>
      <Box sx={{ overflow: "auto", height: "90vh" }}>
        <Stack direction="column" spacing={2} sx={{ position: "sticky" }}>
          <PowerOutputHeader />
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              disableElevation={true}
              onClick={() => setSelectedTab("output")}
              color={selectedTab === "output" ? "primary" : "secondary"}
              variant="contained"
            >
              Power generation
            </Button>
            <Button
              size="small"
              disableElevation={true}
              onClick={() => setSelectedTab("capacity")}
              color={selectedTab === "capacity" ? "primary" : "secondary"}
              variant="contained"
            >
              Capacity factor
            </Button>
          </Stack>
        </Stack>

        {!!selectedProject && !!selectedPeriodType && selectedTimePeriod ? (
          <Stack direction="column" spacing={2} sx={{ pt: 2 }}>
            <Box>
              {!powerDataQuery.isFetching ? (
                <PowerOutputKeyBoxProject />
              ) : (
                <Loading />
              )}
            </Box>
            <Box>
              {!powerDataQuery.isFetching ? (
                <PowerOutputCompareProject selectedTab={selectedTab} />
              ) : (
                <Loading />
              )}
            </Box>
            {!powerDataQuery.isFetching &&
              (selectedTimePeriod.name === "Monthly" ||
                selectedTimePeriod.name === "Quarterly" ||
                selectedTimePeriod.name === "Yearly") && (
                <Box>
                  <PowerOutputCompareAdvancedProject
                    selectedTab={selectedTab}
                  />
                </Box>
              )}
            <Box>
              <PowerOutputHistoricalCapacityAreaChart
                selectedTab={selectedTab}
              />
            </Box>
          </Stack>
        ) : (
          <Box
            sx={{
              background: ({ palette }: GenericType) =>
                palette.background.paper,
              p: 2,
              height: 200,
              borderRadius: 4,
              my: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: ({ palette }: GenericType) => palette.text.primary,
            }}
          >
            <Typography variant="h6" sx={{ py: 2 }}>
              Please select wind farm and time period
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
