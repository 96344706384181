import { GenericType, RouteConfig, ViewTabEnum } from ".";

export const THEME = "wind";
export const FONT_FAMILY = '"Noto Sans", Roboto, helvetica, Arial, sans-serif';

export const ROUTES_CONFIG: { [key: string]: RouteConfig } = {
  WindLeasingAndProject: {
    url: "/",
    title: "Projects",
    viewSwitchBtn: [ViewTabEnum.Map, ViewTabEnum.List],
    searchOptions: [
      "countries",
      "landArea",
      "seaArea",
      "leaseArea",
      "leaseRound",
      "projects",
      "company",
    ],
    enableSearch: true,
    searchAutocomplete: true,
    backBtn: false,
    public: true,
  },
  LeaseRoundTimeLine: {
    url: "/leasing-round-timeline/",
    baseUrl: "/leasing-round-timeline",
    title: "Leasing Round Timeline",
    searchOptions: [],
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    searchInputRightMargin: 28,
    public: false,
  },
  LeaseRoundTimeLineId: {
    url: "/leasing-round-timeline/:id",
    baseUrl: "/leasing-round-timeline",
    title: "Leasing Round Timeline",
    searchOptions: [],
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: true,
    searchInputRightMargin: 28,
    public: false,
  },
  Factory: {
    url: "/factory",
    title: "Factories",
    viewSwitchBtn: [ViewTabEnum.Map, ViewTabEnum.List],
    enableSearch: true,
    searchAutocomplete: true,
    backBtn: false,
    searchOptions: ["countries", "landArea", "company"],
    public: false,
  },
  TurbineModel: {
    url: "/turbine-model",
    title: "Turbine models",
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  FloatingTechnology: {
    url: "/floating-technology",
    title: "Floating technologies",
    viewSwitchBtn: [ViewTabEnum.List, ViewTabEnum.Chart],
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  FactoryContract: {
    url: "/factory-contract/:id",
    baseUrl: "/factory-contract",
    title: "Factory contracts",
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: true,
    public: false,
  },
  Installation: {
    url: "/installation",
    baseUrl: "/installation",
    title: "Installation",
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  CableLay: {
    url: "/cable-lay",
    baseUrl: "/cable-lay",
    title: "Cable lay",
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  Target: {
    url: "/target",
    baseUrl: "/target",
    title: "Targets",
    enableSearch: true,
    searchAutocomplete: true,
    backBtn: false,
    searchOptions: ["countries", "landArea"],
    public: true,
  },
  SupplyDemand: {
    url: "/supply-demand",
    title: "Supply/Demand",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  Contract: {
    url: "/contract",
    title: "Contracts",
    viewSwitchBtn: [ViewTabEnum.List, ViewTabEnum.Chart],
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  PPA: {
    url: "/ppa",
    title: "Power purchase agreements",
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  NewsFeed: {
    url: "/news-feed",
    title: "Analysis",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  PowerOutput: {
    url: "/power-generation",
    title: "Power generation",
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  NewsFeedItem: {
    url: "/news-feed/:id",
    baseUrl: "/news-feed",
    title: "Newsfeed",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: true,
    backBtnUrl: "/news-feed",
    public: false,
  },
  InstallationVesselAvailability: {
    url: "/installation-vessel-availability",
    title: "Installation vessel availability",
    viewSwitchBtn: [ViewTabEnum.List, ViewTabEnum.Chart],
    enableSearch: true,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  Analytics: {
    url: "/analytics",
    title: "Analytics",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: false,
    public: false,
  },
  ActivityVisualisation: {
    url: "/activity-visualisation/:type/:id",
    baseUrl: "/activity-visualisation",
    title: "Activity visualisation",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: true,
    public: false,
  },

  PowerOutputProfile: {
    url: "/power-generation-details",
    title: "Power generation",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: true,
    public: false,
  },
  faq: {
    url: "/faq",
    title: "FAQ",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: false,
    public: true,
  },
  Enquiry: {
    url: "/enquiry",
    title: "",
    enableSearch: false,
    searchAutocomplete: false,
    backBtn: false,
    public: true,
  },
};

const generateModalErrorMessage = (title: string) =>
  `Sorry, we couldn't retrieve the ${title} information. Please check the error details.`;

export const MESSAGE = {
  LeaseArea: generateModalErrorMessage("Lease Area"),
  Country: generateModalErrorMessage("Country"),
  LeaseRound: generateModalErrorMessage("Lease Round"),
  Project: generateModalErrorMessage("Project"),
  NoInfo: `Unfortunately, we were unable to locate any information regarding`,
  UnexpectedError: "An Unexpected Issue Occurred",
  InfoPolygonFetch: `Please hold on a moment while we gather polygon data for your current view.`,
  SuccessPolygonFetch: `Polygon data has been successfully fetched and is now available.`,
  ErrorPolygonFetch: `An error occurred while attempting to fetch the polygon data. Please try again.`,
  SuccessRegionFetch: `Region data has been successfully retrieved and is now available`,
  ErrorRegionFetch: `An error occurred while attempting to fetch the region data. Please try again.`,
  FilterAppliedSuccess: `The filter has been successfully applied, and the relevant data is now updating.`,
  NoResult: `Apologies, no results were found matching the specified criteria.`,
};

export const LETTER_MAPPING: { [key: string]: string[] } = {
  A: ["A", "A", "Ă", "Â"],
  B: ["B", "B"],
  C: ["C", "C"],
  D: ["D", "D", "Đ"],
  E: ["E", "E", "Ê"],
  F: ["F", "F"],
  G: ["G", "G"],
  H: ["H", "H"],
  I: ["I", "I"],
  J: ["J", "J"],
  K: ["K", "K"],
  L: ["L", "L"],
  M: ["M", "M"],
  N: ["N", "N"],
  O: ["O", "O", "Ô", "Ơ"],
  P: ["P", "P"],
  Q: ["Q", "Q"],
  R: ["R", "R"],
  S: ["S", "S"],
  T: ["T", "T"],
  U: ["U", "U", "Ư"],
  V: ["V", "V"],
  W: ["W", "W"],
  X: ["X", "X"],
  Y: ["Y", "Y"],
  Z: ["Z", "Z"],
  AA: ["Å", "Å"],
  AE: ["Ä", "Æ"],
  OE: ["Ö", "Ø"],
};

export const TIMELINE_COLOR: GenericType = {
  submitted: "#88C7FF",
  successful: "#0E9EFF",
  unsuccessful: "#7B7B7B",
  withdrawn: `repeating-linear-gradient(
    45deg,
    #646464,
    #646464 4px,
    #7B7B7B 4px,
    #7B7B7B 10px
  )`,
  "proposed bid": `repeating-linear-gradient(
    45deg,
    white 0 10px,
    #88C7FF 10px 15px
  )`,
};

export const CONTRACT_COLUMN = {
  "turbine-contracts": [
    { label: "Turbine model", name: "turbineModel" },
    {
      label: "Award Date",
      name: "awardDate",
    },
    { label: "Wind farm", name: "windFarm" },
    { label: "Country", name: "country" },
    { label: "Number of units", name: "numberOfUnits" },
  ],
  "floating-contracts": [
    {
      label: "Award Date",
      name: "awardDate",
    },
    { label: "Wind farm", name: "windFarm" },
    { label: "Country", name: "country" },
    { label: "Number of units", name: "numberOfUnits" },
    { label: "Equipment type", name: "equipmentType" },
    { label: "Technology type", name: "technologyType" },
  ],
  "foundation-contracts": [
    {
      label: "Award Date",
      name: "awardDate",
    },
    { label: "Wind farm", name: "windFarm" },
    { label: "Country", name: "country" },
    { label: "Number of units", name: "numberOfUnits" },
    { label: "Foundation type", name: "foundationType" },
    { label: "Max foundation length (m)", name: "maxFoundationLength" },
    { label: "Max foundation weight (t)", name: "maxFoundationWeight" },
    { label: "Max foundation diameter (m)", name: "maxFoundationDiameter" },
    { label: "Max foundation thickness (mm)", name: "maxFoundationThickness" },
  ],
  "cable-contracts": [
    {
      label: "Award Date",
      name: "awardDate",
    },
    { label: "Wind farm", name: "windFarm" },
    { label: "Country", name: "country" },
    { label: "Factories", name: "factories" },
    { label: "Voltage (kV)", name: "voltage" },
    { label: "Number of cables", name: "numberOfCables" },
    { label: "Cable length (km)", name: "cableLength" },
  ],
  "substation-contracts": [
    {
      label: "Award Date",
      name: "awardDate",
    },
    { label: "Wind farm", name: "windFarm" },
    { label: "Country", name: "country" },
    { label: "Substation", name: "substation" },
    { label: "Substation components", name: "components" },
    { label: "Output current", name: "outputCurrent" },
    { label: "Voltage in (kV)", name: "voltageIn" },
    { label: "Voltage out (kV)", name: "voltageOut" },
    { label: "Topside mass (t)", name: "topsideMass" },
    { label: "Topside height (m)", name: "topsideHeight" },
    { label: "Topside width (m)", name: "topsideWidth" },
    { label: "Topside length (m)", name: "topsideLength" },
    { label: "Foundation type", name: "foundationType" },
    { label: "Foundation mass (t)", name: "foundationMass" },
    { label: "Foundation length (m)", name: "foundationLength" },
    { label: "Foundation width (m)", name: "foundationWidth" },
    { label: "Total mass (t)", name: "totalMass" },
    { label: "Number of legs", name: "numberOfLegs" },
  ],
  "tower-contracts": [
    {
      label: "Award Date",
      name: "awardDate",
    },
    { label: "Wind farm", name: "windFarm" },
    { label: "Country", name: "country" },
    { label: "Number of units", name: "numberOfUnits" },
    { label: "Number of sections", name: "numberOfSection" },
    { label: "Tower height (m)", name: "towerHeight" },
    { label: "Tower mass (t)", name: "towerMass" },
  ],
};

export const INSTALLATION_CAPABILITIES = [
  "Maintenance",
  "Transition piece",
  "Pin pile",
  "Monopile",
  "Jacket",
  "Turbine",
];

export const TIMELINE_STATUS = [
  {
    name: "Turbine",
    workType: "Installation",
    equipmentTypes: [11],
  },
  {
    name: "Foundation",
    workType: "Installation",
    equipmentTypes: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  },
  {
    name: "OMS",
    workType: "Maintenance",
    equipmentTypes: [],
  },
  {
    name: "Cable lay",
    workType: "Installation",
    equipmentTypes: [3, 31, 32],
  },
];

export const POWER_OUTPUT_TIME_PERIOD = {
  1: "Hourly",
  2: "Hourly",
  3: "Hourly",
  4: "Daily",
  5: "Daily",
  6: "Daily",
  7: "Daily",
  8: "Weekly",
  9: "Weekly",
  10: "Monthly",
  11: "Monthly",
};
