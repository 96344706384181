/* eslint-disable indent */
import { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, Button, Grid, Typography } from "@esgian/esgianui";
import moment from "moment";

import { ReactComponent as NewsIcon } from "../../assets/news.svg";
import NoImage from "../../assets/no-image.jpg";
import { isFullUrl } from "../../helper/isMatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { ThemeModeEnum } from "../../types";
import { NewsFeedAuthor, NewsFeedLabels } from "../../types/newsFeed";

import "react-lazy-load-image-component/src/effects/blur.css";

type Prop = {
  id: number;
  imageURL: string;
  title: string;
  subTitle: string;
  content: string;
  date: string;
  author: NewsFeedAuthor[];
  labelList: NewsFeedLabels[];
  selected?: boolean;
  handleButtonClick?: () => void;
};

export const NewsFeedBox: FC<Prop> = ({
  title,
  imageURL,
  date,
  author,
  subTitle,
  labelList,
  content,
  selected = false,
  handleButtonClick,
}) => {
  const themeMode = useSelector(getThemeMode);

  const imageSrc = imageURL
    ? isFullUrl(imageURL)
      ? imageURL
      : `${process.env.REACT_APP_IMAGE_URL}/${imageURL}`
    : NoImage;

  return (
    <Box
      sx={{
        background: themeMode === ThemeModeEnum.Dark ? "#171717" : "#D9D9D9",
        py: 2,
        px: 2,
        mt: 2,
        boxShadow: !selected
          ? themeMode === ThemeModeEnum.Dark
            ? "0px 4px 6px rgba(255, 255, 255, 0.1)"
            : "0px 4px 6px rgba(0, 0, 0, 0.1)"
          : null,
      }}
    >
      <Grid container={true} spacing={selected ? 0 : 2}>
        <Grid item={true} xs={selected ? 12 : 6}>
          <Box>
            <Box
              sx={{
                height: selected ? 264 : 208,
                overflow: "hidden",
                position: "relative",
                "& .lazy-load-image-background": {
                  width: "100%",
                },
              }}
            >
              <LazyLoadImage
                alt="Lazy loaded image"
                effect="blur"
                src={imageSrc}
                style={{
                  width: "100%",
                  height: selected ? 264 : 208,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            {!selected ? (
              <Box sx={{ my: 1, flexWrap: "wrap", display: "flex" }}>
                {labelList.map((item, i) => (
                  <Typography
                    key={i}
                    sx={{
                      background:
                        themeMode === ThemeModeEnum.Dark
                          ? "#434343"
                          : "#CBCBCB",
                      padding: "4px 8px",
                      borderRadius: 16,
                      ml: 1,
                      mt: 1,
                      fontSize: 14,
                    }}
                    variant="body2"
                  >
                    {item.tag}
                  </Typography>
                ))}
              </Box>
            ) : null}
          </Box>
          <Box>
            <Typography sx={{ fontSize: 32, mt: selected && 5 }}>
              {title}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography>{moment(date).format("MMM DD, YYYY")}</Typography>
            {author?.length ? (
              <Typography sx={{ ml: 2 }}>
                By
                {author
                  ?.map((item) => `${item.firstName} ${item.lastName}`)
                  .join(", ")}
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid
          item={true}
          xs={selected ? 12 : 6}
          sx={{ pr: selected ? 0 : 10, position: "relative" }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              letterSpacing: 0.15,
              mt: selected && 5,
            }}
          >
            {subTitle}
          </Typography>
          <Box
            sx={{
              mt: 5,
              ...(selected
                ? {}
                : {
                    maxHeight: 200,
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 6,
                    lineClamp: 6,
                  }),
            }}
          >
            {content}
          </Box>

          {!selected ? (
            <Button
              sx={{ position: "absolute", bottom: 16, right: 80 }}
              variant="outlined"
              startIcon={<NewsIcon />}
              onClick={handleButtonClick}
            >
              Full coverage
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
