import { Chart } from "chart.js";

import { GenericType } from "../../../types";

interface CrosshairLine {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

interface HoverLinePluginOptions {
  lineColor?: string; // Default: '#7e7e7e'
  disabled?: boolean; // Default: false
}

export class HoverLinePlugin {
  private crosshair: CrosshairLine | null = null;

  private options: HoverLinePluginOptions;

  /**
   * @param options - Configuration options for the plugin.
   */
  constructor(options: HoverLinePluginOptions = {}) {
    this.options = {
      lineColor: "#7e7e7e",
      disabled: false,
      ...options,
    };
  }

  /**
   * Returns the Chart.js plugin configuration object.
   * @returns Plugin object to be used by Chart.js.
   */
  getPlugin() {
    return {
      id: "hoverLinePlugin",
      events: ["mousemove"] as Array<keyof HTMLElementEventMap>, // Cast to the correct event type
      afterEvent: (chart: Chart, args: GenericType) =>
        this.updateCrosshair(chart, args),
      afterDraw: (chart: Chart) => this.afterRender(chart), // Ensure afterRender is called after drawing
    };
  }

  /**
   * Handles hover events and updates the crosshair line position.
   * @param chart - The Chart.js instance the event occurred on.
   * @param args - Event arguments from Chart.js.
   */
  private updateCrosshair(chart: Chart, args: GenericType) {
    if (this.options.disabled) return;

    const { top, bottom } = chart.chartArea;
    const activePoint = chart.getElementsAtEventForMode(
      args.event,
      "index",
      { intersect: false },
      false,
    )?.[0]?.element;

    // Update crosshair position
    if (!args.inChartArea || !activePoint) {
      this.crosshair = null; // Clear crosshair if mouse is outside the chart or no active point
    } else {
      this.crosshair = {
        startX: activePoint.x,
        startY: top,
        endX: activePoint.x,
        endY: bottom,
      };
    }

    // Mark the chart as needing a re-render after the event
    args.changed = true;
  }

  /**
   * Renders the hover line on the chart.
   * @param chart - The Chart.js instance to render the hover line on.
   */
  private afterRender(chart: Chart) {
    if (!this.crosshair) return;

    const { ctx } = chart;
    ctx.save();
    ctx.beginPath();
    ctx.setLineDash([3]); // Dashed line
    ctx.strokeStyle = this.options.lineColor!;
    ctx.lineWidth = 1;
    ctx.moveTo(this.crosshair.startX, this.crosshair.startY);
    ctx.lineTo(this.crosshair.endX, this.crosshair.endY);
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }
}
