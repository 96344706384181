import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SortingInfo } from "../../types";

export const POWER_GEN_FILTERS = {
  PERIOD_OPTIONS: [
    { id: 1, name: "1 day", number: 1, unit: "days" },
    { id: 2, name: "1 week", number: 1, unit: "weeks" },
    { id: 3, name: "1 month", number: 1, unit: "months" },
    { id: 4, name: "3 months", number: 3, unit: "months" },
    { id: 5, name: "6 months", number: 6, unit: "months" },
    { id: 6, name: "YTD", number: 1, unit: "years" },
    { id: 7, name: "1 year", number: 1, unit: "years" },
    { id: 8, name: "3 years", number: 3, unit: "years" },
    { id: 9, name: "5 years", number: 5, unit: "years" },
    { id: 10, name: "10 years", number: 10, unit: "years" },
    { id: 11, name: "Max", number: 1, unit: "years" },
  ],
  CALENDAR_OPTIONS: [
    { name: "Monthly", id: 1 },
    { name: "Quarterly", id: 2 },
    { name: "Yearly", id: 3 },
  ],
  PERIOD_TYPE_OPTIONS: [
    { id: 1, name: "Trailing time period" },
    {
      id: 2,
      name: "Calendar year",
    },
  ],
};

type PeriodType = {
  id: number;
  name: string;
};
export type TimePeriodType = {
  number?: number;
  unit?:
    | "years"
    | "months"
    | "weeks"
    | "days"
    | "hours"
    | "minutes"
    | "seconds"
    | "milliseconds";
  id: number;
  name: string;
};
type Project = {
  id: number;
  name: string;
  sourceName?:
    | "Elexon"
    | "Taipower"
    | "EEX"
    | "ENTSOE"
    | "Energistyrelsen"
    | "EIA";
};

type State = {
  tableSortOrder: SortingInfo;
  selectedWindProject: Project | null;
  compareWindProjects: Project[] | [];
  periodType: PeriodType | null;
  timePeriod: TimePeriodType | null;
  disabledOption: PeriodType | null;
};

const initialState: State = {
  tableSortOrder: {
    direction: "asc",
    name: "vesselName",
  },
  periodType: POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[0],
  disabledOption: null,
  selectedWindProject: null,
  compareWindProjects: [],
  timePeriod: null,
};

const powerOutputSlice = createSlice({
  name: "powerOutput",
  initialState,
  reducers: {
    setPowerGenTableSort: (state, action: PayloadAction<SortingInfo>) => {
      state.tableSortOrder = action.payload;
    },
    setCompareWindProjects: (state, action: PayloadAction<Project[]>) => {
      state.compareWindProjects = action.payload;
    },
    setPowerGenWindProject: (state, action: PayloadAction<Project>) => {
      if (
        action.payload.sourceName &&
        ["Energistyrelsen", "EIA"].includes(action.payload.sourceName)
      ) {
        state.timePeriod = POWER_GEN_FILTERS.CALENDAR_OPTIONS[0];
        state.periodType = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[1];
        state.disabledOption = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[0];
      } else {
        state.periodType = POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS[0];
        state.timePeriod = POWER_GEN_FILTERS
          .PERIOD_OPTIONS[6] as TimePeriodType;
        state.disabledOption = null;
      }
      state.selectedWindProject = action.payload;
    },
    setPowerGenPeriodType: (state, action: PayloadAction<PeriodType>) => {
      if (action.payload.id === 1) {
        state.timePeriod = POWER_GEN_FILTERS
          .PERIOD_OPTIONS[0] as TimePeriodType;
      } else {
        state.timePeriod = POWER_GEN_FILTERS.CALENDAR_OPTIONS[0];
      }

      state.periodType = action.payload;
    },
    setPowerGenTimePeriod: (state, action: PayloadAction<TimePeriodType>) => {
      state.timePeriod = action.payload;
    },
  },
});

export const {
  setPowerGenTableSort,
  setPowerGenWindProject,
  setPowerGenPeriodType,
  setCompareWindProjects,
  setPowerGenTimePeriod,
} = powerOutputSlice.actions;
export const powerOutput = powerOutputSlice.reducer;
