import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";

import { api } from "../../api/api";
import { getErrorMessage } from "../../helper/errorHandling";
import { safelyCallApi } from "../../helper/requestWrapper";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { GenericType, MESSAGE } from "../../types";
import { useSelector } from "../use-selector";

import { useLookupQueries } from "./useLookupQueries";

const projectPowerDefaults = {
  threeMonthsCapacity: 0,
  threeMonthsCount: 0,
  threeMonthsOutput: 0,
  sixMonthsCapacity: 0,
  sixMonthsCount: 0,
  sixMonthsOutput: 0,
  threeYearsCapacity: 0,
  threeYearsCount: 0,
  threeYearsOutput: 0,
  fiveYearsCapacity: 0,
  fiveYearsCount: 0,
  fiveYearsOutput: 0,
  tenYearsCapacity: 0,
  tenYearsCount: 0,
  tenYearsOutput: 0,
  maxCapacity: 0,
  maxCount: 0,
  maxOutput: 0,
  dailyCapacity: 0,
  dailyCount: 0,
  dailyOutput: 0,
  installedCapacity: 0,
  monthlyCapacity: 0,
  monthlyCount: 0,
  monthlyOutput: 0,
  weeklyCapacity: 0,
  weeklyCount: 0,
  weeklyOutput: 0,
  yearlyCapacity: 0,
  yearlyCount: 0,
  yearlyOutput: 0,
  ytdCapacity: 0,
  ytdCount: 0,
  ytdOutput: 0,
  source: "",
};

const getPowerDataFormatted = (
  data: GenericType,
  lookupProjects: GenericType,
) => {
  const result: GenericType = {};
  data.forEach((item: GenericType) => {
    if (!result[item.projectId]) {
      const lookupProject = lookupProjects[item.projectId];
      result[item.projectId] = {
        ...projectPowerDefaults,
        id: item.projectId,
        name: lookupProject?.name,
        countryId: lookupProject?.countryId,
        capacityMw: lookupProject?.capacityMw,
        sourceName: item?.sourceName,
        data: [],
        generationUnits: [],
      };
    }

    const foundProj = result[item.projectId];
    foundProj.data = [...foundProj.data, item];
    if (!foundProj.generationUnits.includes(item.generationUnitId)) {
      foundProj.generationUnits.push(item.generationUnitId);
    }
    const duration = moment.duration(
      moment().subtract(1, "day").diff(moment(item.day)),
    );
    const durDays = duration.clone().asDays();
    const durWeek = duration.clone().asWeeks();
    const durMonths = duration.clone().asMonths();
    const durYear = duration.clone().asYears();

    if (durDays <= 1) {
      foundProj.dailyOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.dailyCapacity += item.capacityFactor ?? 0;
      foundProj.dailyCount++;
    }
    if (durWeek <= 1) {
      foundProj.weeklyOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.weeklyCapacity += item.capacityFactor ?? 0;
      foundProj.weeklyCount++;
    }
    if (durMonths <= 1) {
      foundProj.monthlyOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.monthlyCapacity += item.capacityFactor ?? 0;
      foundProj.monthlyCount++;
    }
    if (durMonths <= 3) {
      foundProj.threeMonthsOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.threeMonthsCapacity += item.capacityFactor ?? 0;
      foundProj.threeMonthsCount++;
    }
    if (durMonths <= 6) {
      foundProj.sixMonthsOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.sixMonthsCapacity += item.capacityFactor ?? 0;
      foundProj.sixMonthsCount++;
    }
    if (durYear <= 1) {
      foundProj.yearlyOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.yearlyCapacity += item.capacityFactor ?? 0;
      foundProj.yearlyCount++;
    }
    if (durYear <= 3) {
      foundProj.threeYearsOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.threeYearsCapacity += item.capacityFactor ?? 0;
      foundProj.threeYearsCount++;
    }
    if (durYear <= 5) {
      foundProj.fiveYearsOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.fiveYearsCapacity += item.capacityFactor ?? 0;
      foundProj.fiveYearsCount++;
    }
    if (durYear <= 10) {
      foundProj.tenYearsOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.tenYearsCapacity += item.capacityFactor ?? 0;
      foundProj.tenYearsCount++;
    }
    if (moment(item.day).isSameOrAfter(moment().startOf("year"))) {
      foundProj.ytdOutput += (item.generationOutput ?? 0) / 1000;
      foundProj.ytdCapacity += item.capacityFactor ?? 0;
      foundProj.ytdCount++;
    }
    foundProj.maxOutput += (item.generationOutput ?? 0) / 1000;
    foundProj.maxCapacity += item.capacityFactor ?? 0;
    foundProj.maxCount++;
    result[item.projectId] = foundProj;
  });

  Object.keys(result).forEach((farm) => {
    if (result[farm].dailyCount)
      result[farm].dailyCapacity /= result[farm].dailyCount;
    if (result[farm].weeklyCount)
      result[farm].weeklyCapacity /= result[farm].weeklyCount;
    if (result[farm].monthlyCount)
      result[farm].monthlyCapacity /= result[farm].monthlyCount;
    if (result[farm].threeMonthsCount)
      result[farm].threeMonthsCapacity /= result[farm].threeMonthsCount;
    if (result[farm].sixMonthsCount)
      result[farm].sixMonthsCapacity /= result[farm].sixMonthsCount;
    if (result[farm].ytdCount)
      result[farm].ytdCapacity /= result[farm].ytdCount;
    if (result[farm].yearlyCount)
      result[farm].yearlyCapacity /= result[farm].yearlyCount;
    if (result[farm].threeYearsCount)
      result[farm].threeYearsCapacity /= result[farm].threeYearsCount;
    if (result[farm].fiveYearsCount)
      result[farm].fiveYearsCapacity /= result[farm].fiveYearsCount;
    if (result[farm].tenYearsCount)
      result[farm].tenYearsCapacity /= result[farm].tenYearsCount;
    if (result[farm].maxCount)
      result[farm].maxCapacity /= result[farm].maxCount;
  });

  return result;
};

export const usePowerGenQueries = () => {
  const selectedProject = useSelector(getPowerOutputWindProject);
  const compareWindProjects = useSelector(getPowerOutputCompareWindProjects);
  const { lookupProjectsObject } = useLookupQueries();
  const queryClient = useQueryClient(); // Access queryClient instance
  const location = useLocation();

  const powerDataQuery = useQuery({
    queryKey: ["powerGenData"],
    enabled:
      !!lookupProjectsObject &&
      ["/power-generation", "/power-generation-details"].includes(
        location.pathname,
      ),
    placeholderData: null,
    queryFn: () => {
      return safelyCallApi(
        api.powerOutput.getPowerOutputTotal({
          projectIds: Object.keys(lookupProjectsObject ?? {}),
          startTimestamp: moment().subtract(3, "year"),
          endTimestamp: moment().subtract(1, "day"),
          aggregationLevel: "day",
        }),
      )
        .then(({ status, data }) => {
          if (status !== 200) return null;
          return getPowerDataFormatted(data, lookupProjectsObject ?? {});
        })
        .catch((err) => {
          toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
          return null;
        });
    },
  });

  const powerDataFullProjectQuery = useQuery({
    queryKey: ["powerGenDataFull", selectedProject, compareWindProjects],
    enabled:
      !!lookupProjectsObject &&
      !!selectedProject &&
      ["/power-generation-details"].includes(location.pathname),
    placeholderData: null,
    queryFn: ({ queryKey }) => {
      // Get the list of IDs to fetch
      const ids = [
        selectedProject?.id,
        ...(compareWindProjects?.map(({ id }) => id) ?? []),
      ];

      // get the unique queryKey from last time this function was triggered
      const oldKeys = queryClient.getQueryData<unknown>([
        "keys",
        "powerGenDataFull",
      ]);

      let previousData = {};
      // Get previously fetched data from the React Query cache
      if (oldKeys && Array.isArray(oldKeys)) {
        previousData = queryClient.getQueryData<GenericType>(oldKeys) || {};
      }

      // If there is no previous data, all IDs are considered new
      const fetchedIds = previousData ? Object.keys(previousData) : [];

      // Filter out the IDs that have already been fetched (new IDs only)
      const idsToFetch = ids.filter(
        (id) => id && !fetchedIds.includes(id.toString()),
      );

      // If no new IDs to fetch, return the previous data
      if (idsToFetch.length === 0) {
        return Promise.resolve(previousData);
      }

      // Make the API request for new IDs
      return safelyCallApi(
        api.powerOutput.getPowerOutputTotal({
          projectIds: idsToFetch,
          aggregationLevel: "day",
        }),
      )
        .then(({ status, data }) => {
          if (status !== 200) return null;

          // Format the new data
          const formattedData = getPowerDataFormatted(
            data,
            lookupProjectsObject ?? {},
          );

          // Merge the new data with the existing data
          const mergedData = { ...previousData, ...formattedData };

          // Store the current queryKey that is used to retrieve the data
          queryClient.setQueryData(["keys", "powerGenDataFull"], queryKey);

          // Update the React Query cache with the merged data
          queryClient.setQueryData(
            [
              "powerGenDataFull",
              selectedProject?.id,
              compareWindProjects?.map(({ id }) => id).join(","),
            ],
            mergedData,
          );

          return mergedData;
        })
        .catch((err) => {
          toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
          return null;
        });
    },
  });

  const powerDataFullProjectQueryHourly = useQuery({
    queryKey: ["powerGenDataHourly", selectedProject, compareWindProjects],
    enabled:
      !!lookupProjectsObject &&
      !!selectedProject &&
      ["/power-generation-details"].includes(location.pathname),
    placeholderData: null,
    queryFn: ({ queryKey }) => {
      // Get the list of IDs to fetch
      const ids = [
        selectedProject?.id,
        ...(compareWindProjects?.map(({ id }) => id) ?? []),
      ];

      // get the unique queryKey from last time this function was triggered
      const oldKeys = queryClient.getQueryData<unknown>([
        "keys",
        "powerGenDataHourly",
      ]);

      let previousData = {};
      // Get previously fetched data from the React Query cache
      if (oldKeys && Array.isArray(oldKeys)) {
        previousData = queryClient.getQueryData<GenericType>(oldKeys) || {};
      }

      // If there is no previous data, all IDs are considered new
      const fetchedIds = previousData ? Object.keys(previousData) : [];

      // Filter out the IDs that have already been fetched (new IDs only)
      const idsToFetch = ids.filter(
        (id) => id && !fetchedIds.includes(id.toString()),
      );

      // If no new IDs to fetch, return the previous data
      if (idsToFetch.length === 0) {
        return Promise.resolve(previousData);
      }

      // Make the API request for new IDs
      return safelyCallApi(
        api.powerOutput.getPowerOutputTotal({
          projectIds: idsToFetch,
          startTimestamp: moment().subtract(3, "months").subtract(1, "day"),
          endTimestamp: moment().subtract(1, "day"),
          aggregationLevel: "hour",
        }),
      )
        .then(({ status, data }) => {
          if (status !== 200) return null;

          // Format the new data
          const formattedData = getPowerDataFormatted(
            data,
            lookupProjectsObject ?? {},
          );

          // Merge the new data with the existing data
          const mergedData = { ...previousData, ...formattedData };

          // Store the current queryKey that is used to retrieve the data
          queryClient.setQueryData(["keys", "powerGenDataHourly"], queryKey);

          // Update the React Query cache with the merged data
          queryClient.setQueryData(
            [
              "powerGenDataHourly",
              selectedProject?.id,
              compareWindProjects?.map(({ id }) => id).join(","),
            ],
            mergedData,
          );

          return mergedData;
        })
        .catch((err) => {
          toast.error(`${MESSAGE.Project}: ${getErrorMessage(err)}`);
          return null;
        });
    },
  });

  const resetLookupData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["lookupProjects"] });
  }, [queryClient]);

  return {
    powerDataQuery,
    powerDataFullProjectQuery,
    powerDataFullProjectQueryHourly,
    resetLookupData,
  };
};
