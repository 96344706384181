import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  DeleteOutlineIcon,
  FormControl,
  IconButton,
  Stack,
  Table,
  Typography,
} from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { getThemeMode } from "../../store/selector/common";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { setCompareWindProjects } from "../../store/slice/powerOutput";
import { GenericType, SelectedFilterItem } from "../../types";
import { StyledAutocomplete } from "../StyledAutocomplete";

type Prop = {
  sx?: GenericType;
  selectedTab?: string;
};

export const PowerOutputCompareProject: FC<Prop> = ({ sx, selectedTab }) => {
  const dispatch = useDispatch();

  const { powerDataFullProjectQuery, powerDataQuery } = usePowerGenQueries();
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedCompareItems = useSelector(getPowerOutputCompareWindProjects);
  const themeMode = useSelector(getThemeMode);
  const [deletedItems, setDeletedItems] = useState<string[]>([]);

  useEffect(() => {
    if (selectedProject) {
      const updatedCompareItems = selectedCompareItems.filter(
        (item) => item.id !== selectedProject.id,
      );
      if (updatedCompareItems.length !== selectedCompareItems.length) {
        dispatch(setCompareWindProjects(updatedCompareItems));
      }
    }
  }, [selectedProject, selectedCompareItems, dispatch]);

  const tableData = useMemo(() => {
    if (!selectedProject) return [];
    if (!powerDataFullProjectQuery.data) return [];

    const data = [
      (powerDataFullProjectQuery.data as GenericType)[selectedProject.id],
    ];
    const filteredData = data.filter(
      (item) => !deletedItems.includes(item.name),
    );
    filteredData.sort((a: GenericType, b: GenericType) => {
      const aPriority = a.id === selectedProject.id ? 0 : 1; // Highest priority for selectedProject
      const bPriority = b.id === selectedProject.id ? 0 : 1;

      if (aPriority !== bPriority) {
        return aPriority - bPriority;
      }

      // If both are in compareWindProjects, sort by their order in the array
      const aIndex = selectedCompareItems.findIndex((proj) => proj.id === a.id);
      const bIndex = selectedCompareItems.findIndex((proj) => proj.id === b.id);

      return aIndex - bIndex;
    });

    return filteredData;
  }, [
    powerDataFullProjectQuery,
    selectedProject,
    selectedCompareItems,
    deletedItems,
  ]);

  const projectOptions = useMemo(() => {
    if (!powerDataQuery.data) return [];
    if (!selectedProject) return [];
    const ids = [
      ...(selectedCompareItems ? selectedCompareItems.map(({ id }) => id) : []),
      selectedProject.id,
    ];
    return (Object.values(powerDataQuery.data) as GenericType[]).filter(
      (item) => !ids.includes(item.id),
    );
  }, [powerDataQuery, selectedProject, selectedCompareItems]);

  const COLUMNS = useMemo(() => {
    const isCapacity = selectedTab === "capacity";
    const nameExt = isCapacity ? "Capacity" : "Output";
    return [
      {
        label: "Name",
        name: "name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value: string, tableMeta: GenericType) => {
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                {value}
                {tableMeta.rowIndex !== 0 ? (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      const updatedCompareItems = selectedCompareItems.filter(
                        (item) => item.name !== tableMeta.rowData[0],
                      ); // removing the element that has the same name as what is on the first column of the clicked row
                      dispatch(setCompareWindProjects(updatedCompareItems));
                      setDeletedItems((prev) => [
                        ...prev,
                        tableMeta.rowData[0],
                      ]);
                    }}
                  >
                    <DeleteOutlineIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Stack>
            );
          },
        },
      },
      {
        label: "Installed capacity",
        name: "capacityMw",
      },
      {
        label: "1 day",
        name: `daily${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "1 week",
        name: `weekly${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "1 month",
        name: `monthly${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "3 months",
        name: `threeMonths${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "6 months",
        name: `sixMonths${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "YTD",
        name: `ytd${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "1 year",
        name: `yearly${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "3 years",
        name: `threeYears${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "5 years",
        name: `fiveYears${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "10 years",
        name: `tenYears${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "Max",
        name: `max${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
    ];
  }, [selectedTab, selectedCompareItems, dispatch]);

  let title = "";
  if (selectedTab === "capacity") {
    title = "Capacity factor";
  }
  if (selectedTab === "output") {
    title = "Power generation";
  }

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        height: "100%",
        borderRadius: 4,
        ...sx,
      }}
    >
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="h6">{title} overview</Typography>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography>Compare to </Typography>
          <FormControl
            sx={{
              width: 340,
              "& .MuiSelect-select": {
                height: "38px !important",
                lineHeight: "36px",
              },
              mr: 2,
            }}
          >
            <StyledAutocomplete
              loading={projectOptions.length === 0}
              disableClearable={true}
              disableCloseOnSelect={false}
              options={projectOptions}
              selectedItem={[]}
              setSelectedItem={(value) => {
                const tempArray = Array.isArray(value)
                  ? [...selectedCompareItems, ...value]
                  : [...selectedCompareItems, value];
                dispatch(
                  setCompareWindProjects(tempArray as SelectedFilterItem[]),
                );
              }}
              labelKey="name"
              multipleSelection={true}
              size="medium"
              placeholder="Wind farm"
              maxHeight="44vh"
              disabled={selectedCompareItems.length >= 6}
            />
          </FormControl>
        </Stack>
      </Stack>
      {powerDataFullProjectQuery.isFetched ? (
        <Box sx={{ overflowX: "auto" }}>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={tableData}
            elevation={0}
            mode={themeMode}
            options={{
              download: false,
              search: false,
              downloadOptions: {
                filename: `turbine_models_data.csv`,
              },
              expandableRows: false,
              filter: false,
              hideToolbarMargin: false,
              pagination: false,
              responsive: "standard",
              selectableRows: "none",
              showResponsive: false,
              toolbar: true,
              print: false,
              viewColumns: false,
            }}
          />
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};
