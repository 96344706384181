export const hexToRgba = (hex: string, opacity: number) => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const adjustColorOpacity = (
  color?: string | undefined,
  opacity: number = 1,
) => {
  if (!color) return undefined;
  // Check if the color is in hex format
  if (color.startsWith("#")) {
    return hexToRgba(color, opacity);
  }

  // Check if the color is already in rgba format
  if (color.startsWith("rgba")) {
    return color.replace(
      /rgba\((\d+), (\d+), (\d+), [^)]+\)/,
      `rgba($1, $2, $3, ${opacity})`,
    );
  }

  // If the color is in rgb format, convert it to rgba
  if (color.startsWith("rgb")) {
    return color.replace(
      /rgb\((\d+), (\d+), (\d+)\)/,
      `rgba($1, $2, $3, ${opacity})`,
    );
  }

  return color; // Return original color if format is unrecognized
};
