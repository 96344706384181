/**
 * Parses a numeric value and returns it formatted with a minimum of 3 significant digits.
 *
 * - Rounds to an integer if the value is ≥ 100.
 * - Formats to one decimal place if the value is ≥ 10 but < 100.
 * - Formats to two decimal places if the value is ≥ 1 but < 10.
 * - Formats to three decimal places if the value is ≥ 0.1 but < 1.
 * - Formats to four decimal places if the value is ≥ 0.01 but < 0.1.
 * - Uses scientific notation if the value is < 0.01.
 *
 * @param value - The numeric value to be formatted, which can be a number, a string, or `null`.
 * @returns The formatted number as a `number` or `"-"` if the input is invalid or non-numeric.
 */
export const parseNumberToSignificant = (
  value: number | string | null,
): number | string => {
  if (value === null || value === undefined || value === "") return "-";

  let numericValue: number;

  if (typeof value === "string") {
    const parsed = parseFloat(value);
    if (isNaN(parsed)) return "-"; // Return "-" for non-numeric strings
    numericValue = parsed;
  } else {
    numericValue = value;
  }

  const absVal = Math.abs(numericValue);

  let result: string; // Ensure consistent type for `result` in formatted outputs
  if (absVal >= 100) {
    return Math.round(numericValue); // Return rounded number directly
  } else if (absVal >= 10) {
    result = numericValue.toFixed(1); // 1 decimal place for values >= 10
  } else if (absVal >= 1) {
    result = numericValue.toFixed(2); // 2 decimal places for values >= 1
  } else if (absVal >= 0.1) {
    result = numericValue.toFixed(3); // 3 decimal places for values >= 0.1
  } else if (absVal >= 0.01) {
    result = numericValue.toFixed(4); // 4 decimal places for values >= 0.01
  } else {
    result = numericValue.toExponential(2); // Scientific notation for very small values
  }

  return parseFloat(result); // Safely parse the formatted string to a number
};

/**
 * Formats a number with a metric prefix ("K" for thousands, "M" for millions).
 *
 * - Appends "M" for values ≥ 1,000,000, dividing the value by 1,000,000.
 * - Appends "K" for values ≥ 10,000 but < 1,000,000, dividing the value by 1,000.
 * - Optionally parses the formatted value to a minimum of 3 significant digits.
 *
 * @param value - The numeric value to be formatted.
 * @param parseToSignificant - Whether to parse the value to significant digits (default is `true`).
 * @returns The formatted number as a string with an appropriate metric prefix or `"-"` for `null` values.
 */
export const getNumberWithMetricPrefix = (
  value: number,
  parseToSignificant: boolean = true,
): string => {
  let tempVal = value;
  const absVal = Math.abs(value);
  if (value === null) return "-";
  let unit = "";
  if (absVal > 1000000) {
    unit = "M";
    tempVal = tempVal / 1000000;
  } else if (absVal > 10000) {
    unit = "K";
    tempVal = tempVal / 1000;
  }
  return `${parseToSignificant ? parseNumberToSignificant(tempVal) : tempVal}${unit}`;
};
