import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

import "./index.css";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
const staleTime = 1000 * 60 * 15; // Data is valid for 15 minutes

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER as string,
};

TagManager.initialize(tagManagerArgs);
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: staleTime } },
});

root.render(
  <Provider store={store}>
    <Router>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
