import React, { useMemo, useState } from "react";
import { Stack, Typography } from "@esgian/esgianui";
import { Chart, ChartDataset } from "chart.js";

import { ChartData, ChartTimeSeriesDataSet } from "../../types/charts";
import TextWithTooltipIcon from "../TextWithTooltipIcon";

import { adjustColorOpacity } from "./helpers";
import LegendValue from "./LegendValue";

type Prop = {
  chartRef: React.RefObject<Chart | undefined>; // Reference to Chart.js instance
  id: string;
  data: ChartData;
};

function CanvasLegends({ chartRef, id, data }: Prop) {
  const [legendActive, setLegendActive] = useState<number[]>([]);

  const handleLegendClick = (index: number) => {
    const chart = chartRef.current;
    if (chart) {
      const meta = chart.getDatasetMeta(index);
      const value = !chart.data.datasets[index].hidden;
      meta.hidden = meta.hidden === null ? value : !meta.hidden;
      if (meta.hidden) {
        setLegendActive((prev) => [...prev, index]);
      } else {
        setLegendActive((prev) => prev.filter((v) => v !== index));
      }
      chart.update();
    }
  };

  const handleLegendHover = (itemIndex: number, highlight: boolean) => {
    const chart = chartRef.current;
    if (chart) {
      if (!chart.options.animation) {
        chart.options.animation = {};
      }
      chart.options.animation.duration = 200; // Set a faster animation duration

      chart.data.datasets.forEach((dataset, index) => {
        // Assert the dataset type to include originalBackgroundColor
        const typedDataset = dataset as ChartDataset & {
          originalBackgroundColor?: string;
        };

        if (highlight && index !== itemIndex) {
          // Check if borderColor and backgroundColor are strings before applying adjustColorOpacity
          if (typeof typedDataset.borderColor === "string") {
            typedDataset.borderColor = adjustColorOpacity(
              typedDataset.borderColor,
              0.1,
            );
          }
          if (typeof typedDataset.backgroundColor === "string") {
            typedDataset.backgroundColor = adjustColorOpacity(
              typedDataset.backgroundColor,
              0.1,
            );
          }
        } else {
          // Restore original colors if available
          if (typeof typedDataset.borderColor === "string") {
            typedDataset.borderColor = adjustColorOpacity(
              typedDataset.borderColor,
              1,
            ); // Full opacity
          }

          // Restore original backgroundColor if defined, otherwise fallback to current backgroundColor
          typedDataset.backgroundColor =
            typedDataset.originalBackgroundColor ??
            typedDataset.backgroundColor;
        }
      });

      // Update the chart with the new dataset modifications
      chart.update();
    }
  };

  const renderLegend = (
    dataset: ChartTimeSeriesDataSet[],
    groupName: string | null,
    key: string,
  ) => (
    <Stack
      direction="row"
      spacing={1}
      key={key}
      sx={{ rowGap: "normal" }}
      useFlexGap={true}
      flexWrap="wrap"
    >
      {groupName && <Typography variant="caption">{groupName}:</Typography>}
      {dataset
        .filter(({ hidden }) => !hidden)
        .map((dataItem, i) => {
          const {
            type,
            label,
            legendTooltip,
            backgroundColor,
            borderColor,
            disableLegend,
            subHeader,
            order,
            subHeaderTooltip,
          } = dataItem;

          if (subHeader) {
            if (subHeaderTooltip) {
              return (
                <TextWithTooltipIcon
                  key={`${id}-legend-${i}`}
                  iconSize="16px"
                  gap="4px"
                  label={
                    <Typography key={`${id}-legend-${i}`} variant="body2">
                      {subHeader}
                    </Typography>
                  }
                  tooltipText={subHeaderTooltip}
                />
              );
            } else {
              return (
                <Typography key={`${id}-legend-${i}`} variant="body2">
                  {subHeader}
                </Typography>
              );
            }
          }

          if (legendTooltip && !disableLegend) {
            return (
              <TextWithTooltipIcon
                key={`${id}-legend-${i}`}
                iconSize="16px"
                gap="4px"
                label={
                  <LegendValue
                    handleHover={(highlight) =>
                      handleLegendHover(order ?? i, highlight)
                    }
                    inactive={legendActive.includes(order ?? i)}
                    onClick={() => handleLegendClick(order ?? i)}
                    value={label}
                    color={type === "bar" ? backgroundColor : borderColor}
                  />
                }
                tooltipText={legendTooltip}
              />
            );
          }

          return !disableLegend ? (
            <LegendValue
              handleHover={(highlight) =>
                handleLegendHover(order ?? i, highlight)
              }
              inactive={legendActive.includes(order ?? i)}
              onClick={() => handleLegendClick(order ?? i)}
              key={`${id}-legend-${i}`}
              value={label}
              color={type === "bar" ? backgroundColor : borderColor}
            />
          ) : null;
        })}
    </Stack>
  );

  const legendGroups = useMemo(() => {
    const groupList: string[] = [];
    data.datasets.forEach(({ group }) => {
      if (group && !groupList.includes(group)) {
        groupList.push(group);
      }
    });
    return groupList;
  }, [data]);

  if (legendGroups.length) {
    return (
      <Stack spacing={1}>
        {legendGroups.map((groupName, i) => {
          const currSet = data.datasets.filter(
            ({ group }) => group === groupName,
          );
          return renderLegend(currSet, groupName, `legend-${i}`);
        })}
      </Stack>
    );
  }

  return renderLegend(data.datasets, null, "legend-default");
}

export default CanvasLegends;
