import React, { useMemo } from "react";
import { Stack, Typography } from "@esgian/esgianui";

import { useTheme } from "../../hooks/useTheme";
type Color = {
  name: string;
  index: number;
  isSingle: boolean;
};

type Prop = {
  color?: Color | string | null;
  sx?: Record<string, unknown>;
  inactive?: boolean;
  onClick?: () => void;
  handleHover?: (val: boolean) => void;
  value?: string;
  textVariant?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
  isLine?: boolean;
};

function LegendValue({
  handleHover = () => {}, // Default to no-op function
  onClick = undefined,
  inactive = false,
  textVariant = "caption",
  color = null,
  value = "",
  sx = {},
  isLine = false,
}: Prop) {
  const { theme } = useTheme();

  const background = useMemo(() => {
    if (!color) return undefined;
    if (typeof color === "string") {
      return color;
    }

    const {
      palette: { charts },
    } = theme;

    if (color.isSingle) {
      return charts[color.name];
    }

    return charts[color.name][color.index];
  }, [color, theme]);

  const inactiveStyle = inactive
    ? {
        opacity: 0.8,
        filter: "brightness(0.8)",
      }
    : {};

  const legendSx = {
    ...sx,
    cursor: onClick ? "pointer" : "default",
    ...inactiveStyle,
  };

  return (
    <Stack
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onClick={onClick}
      sx={legendSx}
      direction="row"
      alignItems="center"
      spacing={0.5}
    >
      <div
        style={{
          height: isLine ? "3px" : "8px",
          width: "8px",
          background: background,
        }}
      />
      <Typography sx={{ lineHeight: "16px" }} variant={textVariant}>
        {value}
      </Typography>
    </Stack>
  );
}

export default LegendValue;
