import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getEsgianTheme } from "@esgian/esgianui";

import { getThemeMode } from "../store/selector/common";
import { THEME } from "../types";

export const useTheme = () => {
  const themeMode = useSelector(getThemeMode);

  const theme = useMemo(() => {
    const tempTheme = getEsgianTheme(themeMode ? "dark" : "light", THEME);
    return { ...tempTheme, ...{ mode: tempTheme.palette.mode } };
  }, [themeMode]);

  return { theme };
};
