import React, { FC, useCallback, useMemo, useRef } from "react";
import { Box, Stack, Typography } from "@esgian/esgianui";
import { CoreScaleOptions, Scale } from "chart.js/dist/types";
import moment from "moment";

import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { useTheme } from "../../hooks/useTheme";
import {
  getPowerOutputCompareWindProjects,
  getPowerOutputPeriodStartDate,
  getPowerOutputPeriodType,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { GenericType } from "../../types";
import { ChartTimeSeriesDataSet } from "../../types/charts";
import { AxisTitlePlugin, HoverLinePlugin } from "../Charts";
import CanvasTimeSeriesChart from "../Charts/CanvasTimeSeriesChart";

// Define the structure for wind farm data
type WindFarmData = {
  generationOutput: number;
  day: string;
  capacityFactor: number;
};

type Prop = {
  name?: string;
  selectedTab?: string;
};
type PowerData = {
  name: string;
  data: WindFarmData[]; // Replace `any[]` with the correct type for the data array
};

export const PowerOutputHistoricalCapacityAreaChart: FC<Prop> = ({
  selectedTab,
}) => {
  const chartRef = useRef(undefined);
  const { powerDataFullProjectQuery, powerDataFullProjectQueryHourly } =
    usePowerGenQueries();
  const startDate = useSelector(getPowerOutputPeriodStartDate);
  const selectedPeriodType = useSelector(getPowerOutputPeriodType);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const selectedProject = useSelector(getPowerOutputWindProject);
  const compareWindProjects = useSelector(getPowerOutputCompareWindProjects);

  const {
    theme: {
      palette: {
        text: { primary },
      },
    },
  } = useTheme();

  const series = useMemo(() => {
    if (!powerDataFullProjectQuery.data) return [];
    const res: ChartTimeSeriesDataSet[] = [];
    const ids: number[] = [];
    if (selectedProject) {
      ids.push(selectedProject.id);
    }
    if (compareWindProjects.length) {
      compareWindProjects.forEach(({ id }) => ids.push(id));
    }
    let queryData: Record<string, PowerData>;

    if (
      selectedTimePeriod &&
      selectedTimePeriod.id < 7 &&
      selectedPeriodType?.id === 1
    ) {
      queryData = powerDataFullProjectQueryHourly.data as Record<
        string,
        PowerData
      >;
    } else {
      queryData = powerDataFullProjectQuery.data as Record<string, PowerData>;
    }
    ids.forEach((id: number) => {
      const windFarm = queryData[id]; // No TypeScript error here now
      if (windFarm) {
        const tempSeries: ChartTimeSeriesDataSet = {
          label: windFarm.name,
          type: "line",
          data: [],
        };
        windFarm.data?.forEach(
          ({ generationOutput, day, capacityFactor }: WindFarmData) => {
            const currDay = moment(day);
            if (currDay.isAfter(startDate)) {
              tempSeries.data.push({
                x: moment(day).valueOf(),
                y: selectedTab === "output" ? generationOutput : capacityFactor,
              });
            }
          },
        );
        tempSeries.data = [...tempSeries.data].sort((a, b) => a.x - b.x);
        res.push(tempSeries);
      }
    });

    return res;
  }, [
    compareWindProjects,
    powerDataFullProjectQueryHourly,
    selectedPeriodType,
    selectedTimePeriod,
    powerDataFullProjectQuery.data,
    selectedProject,
    startDate,
    selectedTab,
  ]);

  // TODO spec out options
  const renderChartLabels = useCallback(
    function (
      this: Scale<CoreScaleOptions>,
      tickValue: string | number,
      index: number,
    ): string | number | null | undefined {
      const currentDate = moment(this.getLabelForValue(index));

      if (selectedPeriodType?.id === 1) {
        if (selectedTimePeriod?.id ?? 0 < 5) {
          return currentDate.format("YYYY-MM-DD HH:mm");
        }
        if (selectedTimePeriod?.id ?? 0 < 8) {
          return currentDate.format("YYYY-MM-DD");
        }
        if (selectedTimePeriod?.id ?? 0 < 11) {
          return currentDate.format("MMM YYYY");
        }
        return currentDate.format("YYYY");
      }
      return currentDate.format("YYYY-MM-DD");
    },
    [selectedPeriodType, selectedTimePeriod],
  );

  const plugins = useMemo(() => {
    return [
      new AxisTitlePlugin([
        {
          text: "GWh",
          fontSize: "10px",
          fontColor: primary,
          YPosition: "top",
          XPosition: "left",
        },
      ]).getPlugin(),
      new HoverLinePlugin({ lineColor: primary }).getPlugin(),
    ];
  }, [primary]);

  const isEmpty = useMemo(() => {
    return (
      !series.length || // No series
      series.every(({ data }) => !data.length) // All series have no data
    );
  }, [series]);

  let title = "";
  if (selectedTab === "capacity") {
    title = "Capacity factor";
  }
  if (selectedTab === "output") {
    title = "Power generation";
  }

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        borderRadius: 4,

        color: ({ palette }: GenericType) => palette.text.primary,
      }}
    >
      {isEmpty ? (
        <Box
          sx={{
            height: "22rem",
            justifyItems: "center",
            alignContent: "center",
          }}
        >
          <Typography variant="h6">No data. Please update filters</Typography>
        </Box>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h6">{title} over time</Typography>
          <CanvasTimeSeriesChart
            plugins={plugins}
            customLabelRender={renderChartLabels}
            chartRef={chartRef}
            loading={!series.length}
            series={series}
            type="line"
            id="test"
          />
        </Stack>
      )}
    </Box>
  );
};
