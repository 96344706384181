import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography } from "@esgian/esgianui";
import moment from "moment";

import { ReactComponent as CopyIcon } from "../../assets/copy.svg";
import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin.svg";
import NoImage from "../../assets/no-image.jpg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { Loading } from "../../components/Loading";
import { NewsFeedBox } from "../../components/NewsFeedBox";
import { isFullUrl } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getIsLoading,
  getNewsFeedSelectedItem,
  getProductId,
} from "../../store/selector/newsFeed";
import {
  fetchAuthorList,
  fetchNewsFeedItem,
  fetchProduct,
  fetchTagList,
  setSelectedItem,
} from "../../store/slice/newsFeed";
import { ROUTES_CONFIG, ThemeModeEnum } from "../../types";

export const NewFeedItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentUrl = window.location.href;
  const selectedItem = useSelector(getNewsFeedSelectedItem);
  const productId = useSelector(getProductId);
  const themeMode = useSelector(getThemeMode);
  const loading = useSelector(getIsLoading);
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}`;
  const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;

  const handleButtonClick = (selectedId: number) => {
    navigate(`${ROUTES_CONFIG.NewsFeedItem.baseUrl}/${selectedId}`);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(currentUrl);
  };

  useEffect(() => {
    dispatch(fetchProduct());
    dispatch(fetchTagList());
    dispatch(fetchAuthorList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNewsFeedItem(Number(id)));
  }, [dispatch, id, productId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedItem(null));
    };
  }, [dispatch]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 100px)",
        mx: 2,
        px: 12,
        pb: 2,
        overflow: "auto",
        background: themeMode === ThemeModeEnum.Dark ? "#171717" : "D9D9D9",
      }}
    >
      {!loading ? (
        <>
          {selectedItem ? (
            <NewsFeedBox
              id={selectedItem.selectedItem.id}
              imageURL={selectedItem.selectedItem.contentImages?.[0]?.path}
              title={selectedItem.selectedItem.title}
              subTitle={selectedItem.selectedItem.preface}
              author={selectedItem.selectedItem.authors}
              labelList={selectedItem.selectedItem.tags}
              date={selectedItem.selectedItem.created}
              content={selectedItem.selectedItem.content}
              selected={true}
            />
          ) : null}
          <Box sx={{ display: "flex", mt: 5, justifyContent: "end" }}>
            <Button onClick={copyToClipboard} variant="text">
              <CopyIcon />
              <Typography sx={{ ml: 1 }} variant="body2">
                SHARE
              </Typography>
            </Button>
            <Button
              component="a"
              href={facebookShareUrl}
              target="_blank"
              rel="noopener noreferrer"
              variant="text"
            >
              <FacebookIcon />
            </Button>
            <Button
              component="a"
              href={twitterShareUrl}
              target="_blank"
              variant="text"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </Button>
            <Button
              component="a"
              href={linkedInShareUrl}
              target="_blank"
              variant="text"
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
            </Button>
          </Box>

          <Box sx={{ mt: 5 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
              Relevant:
            </Typography>
            <Box
              sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}
            >
              {selectedItem?.relatedItems.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    width: "32%",
                    cursor: "pointer",
                    mt: 2,
                    p: 1,
                    boxShadow:
                      themeMode === ThemeModeEnum.Dark
                        ? "0px 4px 6px rgba(255, 255, 255, 0.1)"
                        : "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => handleButtonClick(item.id)}
                >
                  <Box
                    sx={{
                      background: item.contentImages.length
                        ? isFullUrl(item.contentImages?.[0]?.path)
                          ? `url(${item.contentImages?.[0]?.path}) center / cover no-repeat`
                          : `url(${process.env.REACT_APP_IMAGE_URL}/${item.contentImages?.[0]?.path}) center / cover no-repeat`
                        : `url(${NoImage}) center / cover no-repeat`,
                      height: 84,
                    }}
                  />
                  <Typography sx={{ mt: 2, fontSize: 16, fontWeight: 600 }}>
                    {item.title}
                  </Typography>
                  <Box sx={{ mt: 2, display: "flex" }}>
                    <Typography sx={{ fontSize: 12 }}>
                      {moment(item.created).format("MMM DD, YYYY")}
                    </Typography>
                    {item.authors ? (
                      <Typography sx={{ fontSize: 12, ml: 2 }}>
                        By
                        {item.authors
                          ?.map((a) => `${a.firstName} ${a.lastName}`)
                          .join(", ")}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
