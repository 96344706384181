import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Table,
  Typography,
} from "@esgian/esgianui";

import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import { getSortingInfo } from "../../store/selector/powerOutput";
import { setRowsPerPage } from "../../store/slice/common";
import {
  setPowerGenTableSort,
  setPowerGenWindProject,
} from "../../store/slice/powerOutput";
import { GenericType, PowerOutputEnum, ROUTES_CONFIG } from "../../types";
import { Loading } from "../Loading";

type Prop = {
  tab: PowerOutputEnum;
  selectedView: string;
  setSelectedView: (selectedValue: string) => void;
  tableData: GenericType;
};

export const PowerOutputProjectListWindFarm: FC<Prop> = ({
  tab,
  selectedView,
  setSelectedView,
  tableData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortingInfo = useSelector(getSortingInfo);
  const rowsPerPage = useSelector(getRowsPerPage);
  const themeMode = useSelector(getThemeMode);
  const searchText = useSelector(getSearchText);
  const [debouncedSearchText, setDebouncedSearchText] = useState("");

  const title = useMemo(() => {
    return tab.toLocaleLowerCase().replace("-", " ") + "s";
  }, [tab]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText ?? "");
    }, 500); // Delay of 1 second

    return () => {
      clearTimeout(handler); // Cleanup on unmount or if `searchText` changes
    };
  }, [searchText]);

  const data = useMemo(() => {
    if (!tableData) return [];
    let list = [...Object.values(tableData)];
    if (debouncedSearchText?.length && list?.length) {
      list = list?.filter((item: GenericType) =>
        isMatch(item, [debouncedSearchText]),
      );
    }
    return list.map((item) => {
      return {
        ...item,
        name: { name: item.name, id: item.id, sourceName: item.sourceName },
      };
    });
  }, [debouncedSearchText, tableData]);
  const isCapacity = selectedView === "Capacity";
  const nameExt = isCapacity ? "Capacity" : "Output";

  const COLUMNS = useMemo(
    () => [
      {
        label: "Name",
        name: "name",
        options: {
          customBodyRender: (value: GenericType) => {
            if (!value) {
              return "N/A";
            }
            return (
              <Typography
                sx={{ cursor: "pointer" }}
                variant="caption"
                onClick={() => {
                  dispatch(
                    setPowerGenWindProject({
                      sourceName: value.sourceName,
                      name: value.name,
                      id: value.id,
                    }),
                  );
                  navigate(`${ROUTES_CONFIG.PowerOutputProfile.url}`);
                }}
              >
                {value.name}
              </Typography>
            );
          },
        },
      },
      {
        label: "Current installed capacity (MW)",
        name: "capacityMw",
      },
      {
        label: "1 week",
        name: `weekly${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "1 month",
        name: `monthly${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "3 months",
        name: `threeMonths${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "6 months",
        name: `sixMonths${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "YTD",
        name: `ytd${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "1 year",
        name: `yearly${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
      {
        label: "3 years",
        name: `threeYears${nameExt}`,
        options: {
          customBodyRender: (value: string): string => {
            if (value) {
              return `${Number(value).toFixed(2)} ${isCapacity ? " %" : ""}`;
            }
            return "";
          },
        },
      },
    ],
    [dispatch, navigate, isCapacity, nameExt],
  );

  return (
    <Box sx={{ px: 2 }}>
      {data ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} ${title}`}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={5}
              alignItems="baseline"
            >
              <Stack spacing={1} direction="row" alignItems="baseline">
                <Typography variant="body2">View by</Typography>
                <FormControl disabled={false} size="small">
                  <Select
                    variant="outlined"
                    inputProps={{ sx: { pl: 1, pr: 1, pt: 0.5, pb: 0.5 } }}
                    value={selectedView}
                    onChange={({ target }: GenericType) =>
                      setSelectedView(target.value)
                    }
                    autoWidth="true"
                  >
                    <MenuItem value="Output">Power generation</MenuItem>
                    <MenuItem value="Capacity">Capacity factor</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <Table
            app="wind"
            title={`${selectedView === "Output" ? "Power output" : "Capacity factor"} ${title} ${selectedView === "Output" ? "- GWh" : ""}`}
            columnDefs={[]}
            columns={COLUMNS}
            data={data || []}
            elevation={0}
            mode={themeMode}
            options={{
              download: true,
              search: false,
              downloadOptions: {
                filename: `power_output_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "dropdown",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "vertical",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },

              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setPowerGenTableSort({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 320px)",
              toolbar: true,
              print: false,
              viewColumns: false,
              sortThirdClickReset: true,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
