import React from "react";
import { Box, HelpOutlineIcon, ToolTip, Typography } from "@esgian/esgianui";

// Define the type for the props
type Props = {
  gap?: string | number | 1;
  iconSize?: string | "16px";
  labelVariant?: string | "subtitle2";
  tooltipText: string | React.ReactNode;
  label: string | React.ReactNode;
  styles?: Record<string, unknown>;
};

function TextWithTooltipIcon({
  gap,
  iconSize,
  labelVariant,
  label,
  tooltipText,
  styles,
}: Props) {
  // Function to render tooltip content based on the type of tooltipText
  const renderTooltipContent = () => {
    if (typeof tooltipText === "string") {
      return tooltipText;
    } else if (React.isValidElement(tooltipText)) {
      // If tooltipText is a React element, clone and pass additional props
      return React.cloneElement(tooltipText as React.ReactElement, {
        component: "span",
      });
    }
    return null; // Return null if tooltipText is neither string nor React element
  };

  return (
    <Box sx={{ display: "flex", gap, alignItems: "center", ...styles }}>
      {/* Check if label is a string or React component */}
      {typeof label === "string" || label instanceof String ? (
        <Typography variant={labelVariant}>{label}</Typography>
      ) : (
        label
      )}

      {/* Tooltip with the dynamic content */}
      <ToolTip title={renderTooltipContent()}>
        <HelpOutlineIcon sx={{ fontSize: iconSize }} />
      </ToolTip>
    </Box>
  );
}

export default TextWithTooltipIcon;
