import { FC, useEffect, useMemo, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  ApexChart,
  Box,
  Divider,
  getEsgianTheme,
  Typography,
} from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { setSelectedFloatingTechnologyId } from "../../store/slice/common";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    FloatingTechnologyChart: {
      width: "99.9%",
      "& .tooltip": {
        py: 1,
        px: 2,
        "& .tooltip-title": {
          display: "flex",
          "& .tooltip-title_subitem": {
            marginLeft: 4,
          },
        },
        "& .tooltip-subtitle": {
          mr: 1,
        },
      },
      "& .apexcharts-menu": {
        background:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        "& .apexcharts-menu-item": {
          ":hover": {
            color: theme.palette.common.black,
          },
        },
      },
    },
  };
};

type Props = {
  type: "horizontal" | "vertical";
  title: string;
  data: (string | number)[][];
  multiSeries?: boolean;
  loading?: boolean;
  height?: number;
};
export const BarChart: FC<Props> = ({
  type,
  title,
  data,
  multiSeries = false,
  loading,
  height = 450,
}) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const classes = useStyles(themeMode);

  const [groups, setGroups] = useState<GenericType>();
  const [xaxis, setXaxis] = useState<string[] | null>(null);
  const [firstDataset, setFirstDataset] = useState<number[] | null>(null);
  const [secondDataset, setSecondDataset] = useState<number[] | null>(null);

  const series = useMemo(() => {
    if (type === "vertical") {
      const groupNames = new Set(data?.map((item) => item[3]));

      const seriesArray = Array.from(groupNames)
        .sort()
        .map((groupName) => ({
          name: groupName,
          data: xaxis?.map((year) => groups?.[year][groupName] || 0),
        }));
      return seriesArray;
    } else {
      return [
        {
          name: "Operation date announced",
          data: firstDataset || [],
        },
        {
          name: "Operation date not announced",
          data: secondDataset || [],
        },
      ];
    }
  }, [data, firstDataset, groups, secondDataset, type, xaxis]);

  const options = useMemo(
    () => ({
      chart: {
        type: "bar",
        height,
        stacked: true,
        stackType: type === "vertical" ? "100%" : undefined,
        background:
          themeMode === ThemeModeEnum.Dark && theme.palette.common.black,
        events: {
          mouseMove: function (
            _: unknown,
            __: unknown,
            config: {
              globals: {
                dom: {
                  Paper: GenericType;
                  baseEl: { querySelectorAll: (arg0: string) => GenericType };
                };
              };
            },
          ) {
            if (config.globals.dom.Paper) {
              const yAxisLabels = config.globals.dom.baseEl.querySelectorAll(
                ".apexcharts-yaxis-label",
              );
              yAxisLabels.forEach((label: { style: { cursor: string } }) => {
                label.style.cursor = "pointer";
              });
            }
          },
        },
      },
      title: {
        text: title,
        style: {
          color:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
      plotOptions: {
        bar: {
          horizontal: type === "horizontal" ? true : false,
          columnWidth: type === "vertical" ? "40%" : "5%",
          barHeight: "18px",
          endingShape: "rounded",
        },
      },
      tooltip: {
        theme: themeMode,
        show: true,
        style: {
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
        },
        followCursor: true,
        intersect: true,
        custom: ({ seriesIndex, dataPointIndex }: GenericType) => {
          const item = data[dataPointIndex];
          return renderToString(
            <Box className="tooltip">
              <Box className="tooltip-title">
                <Typography variant="body2">
                  {type === "vertical" ? series[seriesIndex].name : item[0]}
                </Typography>
                {type === "vertical" ? (
                  <Typography className="tooltip-title_subitem" variant="body2">
                    {Math.round(
                      (series?.[seriesIndex]?.data?.[dataPointIndex] /
                        (series as GenericType[])?.reduce(
                          (sum: number, c: GenericType) =>
                            sum + c.data[dataPointIndex],
                          0,
                        )) *
                        100,
                    )}
                    %
                  </Typography>
                ) : null}
              </Box>
              <Divider />
              <Box>
                <Typography variant="body2" className="tooltip-subtitle">
                  Capacity MW:
                </Typography>
                <Typography variant="body2">
                  {type === "vertical"
                    ? series?.[seriesIndex]?.data?.[dataPointIndex]
                    : item[1 + seriesIndex]}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" className="tooltip-subtitle">
                  Company:
                </Typography>
                <Typography variant="body2">
                  {type === "vertical"
                    ? firstDataset?.[dataPointIndex]
                    : item[3]}
                </Typography>
              </Box>
            </Box>,
          );
        },
        export: {
          theme: themeMode,
          png: {
            filename: "floating-technology",
          },
          autoSelected: "zoom",
        },
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: xaxis || [],
        labels: {
          style: {
            colors:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value: number) => {
            if (type === "vertical") {
              return value.toFixed(0);
            }
            return value;
          },
          style: {
            colors:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "left",
        labels: {
          colors:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
          useSeriesColors: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: type === "horizontal" ? true : false,
          },
        },
        yaxis: {
          lines: {
            show: type === "horizontal" ? false : true,
          },
        },
      },
      colors:
        type === "vertical"
          ? undefined
          : ["rgb(0, 100, 255)", "rgb(152, 152, 160)"],
    }),
    [
      height,
      type,
      title,
      themeMode,
      theme.palette.common.white,
      theme.palette.common.black,
      xaxis,
      data,
      series,
      firstDataset,
    ],
  );

  useEffect(() => {
    let categories = data?.map((item) => item[0] as string);
    if (multiSeries) {
      const groupedByYear: GenericType = {};
      data?.forEach(([year, , value, groupName]) => {
        if (!groupedByYear[year]) {
          groupedByYear[year] = {};
        }
        groupedByYear[year][groupName] =
          (groupedByYear[year][groupName] || 0) + value;
      });
      categories = Object.keys(groupedByYear);
      setGroups(groupedByYear);
    }

    const firstData = data?.map((item) => item[1] as number);
    const secondData = data?.map((item) => item[2] as number);
    setXaxis(categories);
    setFirstDataset(firstData);
    setSecondDataset(secondData);
  }, [data, multiSeries]);

  const selectDataByYAxisLabel = (label: string) => {
    const matchedData = data.find((item) => item.includes(label));
    if (matchedData) {
      dispatch(setSelectedFloatingTechnologyId(Number(matchedData[4])));
    }
  };

  useEffect(() => {
    const handleYAxisClick = (event: GenericType) => {
      const clickedLabel = event.target.textContent;
      selectDataByYAxisLabel(clickedLabel);
    };

    const yAxisLabels = document.querySelectorAll(".apexcharts-yaxis text");
    yAxisLabels.forEach((label) =>
      label.addEventListener("click", handleYAxisClick),
    );

    return () => {
      yAxisLabels.forEach((label) =>
        label.removeEventListener("click", handleYAxisClick),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options]);

  return (
    <Box sx={classes.FloatingTechnologyChart}>
      <ApexChart
        height={height}
        options={options}
        type="bar"
        data={series}
        loading={loading}
      />
    </Box>
  );
};
