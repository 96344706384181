import { FC, useMemo, useRef } from "react";
import {
  Box,
  CircularProgress,
  ProgressBar,
  Stack,
  Typography,
} from "@esgian/esgianui";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import { api } from "../../api/api";
import { safelyCallApi } from "../../helper/requestWrapper";
import { useSelector } from "../../hooks/use-selector";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import {
  getPowerOutputPeriodType,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import { GenericType } from "../../types";
import TextWithTooltipIcon from "../TextWithTooltipIcon";

export const PowerOutputKeyBoxProject: FC = () => {
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedPeriodType = useSelector(getPowerOutputPeriodType);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const { powerDataFullProjectQuery } = usePowerGenQueries();

  const helpingText = useRef("");

  const farmData = useMemo(() => {
    const result = {
      generationOutput: 0,
      capacityFactor: 0,
      minCap: 0,
      maxCap: 0,
      powerCoverage: 0,
    };
    if (!powerDataFullProjectQuery.data) return result;
    if (!selectedProject || !selectedTimePeriod) return result;
    const tempFarmData = (powerDataFullProjectQuery.data as GenericType)[
      selectedProject.id
    ] as GenericType;

    let tempPowerData = 0;

    if (selectedPeriodType?.id === 1) {
      if (selectedTimePeriod.id === 1) {
        result.generationOutput = tempFarmData.dailyOutput;
        result.capacityFactor = tempFarmData.dailyCapacity;
        tempPowerData = tempFarmData.dailyCount;
      }
      if (selectedTimePeriod.id === 2) {
        result.generationOutput = tempFarmData.weeklyOutput;
        result.capacityFactor = tempFarmData.weeklyCapacity;
        tempPowerData = tempFarmData.weeklyCount;
      }
      if (selectedTimePeriod.id === 3) {
        result.generationOutput = tempFarmData.monthlyOutput;
        result.capacityFactor = tempFarmData.monthlyCapacity;
        tempPowerData = tempFarmData.monthlyCount;
      }
      if (selectedTimePeriod.id === 4) {
        result.generationOutput = tempFarmData.threeMonthsOutput;
        result.capacityFactor = tempFarmData.threeMonthsCapacity;
        tempPowerData = tempFarmData.threeMonthsCount;
      }
      if (selectedTimePeriod.id === 5) {
        result.generationOutput = tempFarmData.sixMonthsOutput;
        result.capacityFactor = tempFarmData.sixMonthsCapacity;
        tempPowerData = tempFarmData.sixMonthsCount;
      }
      if (selectedTimePeriod.id === 6) {
        result.generationOutput = tempFarmData.ytdOutput;
        result.capacityFactor = tempFarmData.ytdCapacity;
        tempPowerData = tempFarmData.ytdCount;
      }
      if (selectedTimePeriod.id === 7) {
        result.generationOutput = tempFarmData.yearlyOutput;
        result.capacityFactor = tempFarmData.yearlyCapacity;
        tempPowerData = tempFarmData.yearlyCount;
      }
      if (selectedTimePeriod.id === 8) {
        result.generationOutput = tempFarmData.threeYearsOutput;
        result.capacityFactor = tempFarmData.threeYearsCapacity;
        tempPowerData = tempFarmData.threeYearsCount;
      }
      if (selectedTimePeriod.id === 9) {
        result.generationOutput = tempFarmData.fiveYearsOutput;
        result.capacityFactor = tempFarmData.fiveYearsCapacity;
        tempPowerData = tempFarmData.fiveYearsCount;
      }
      if (selectedTimePeriod.id === 10) {
        result.generationOutput = tempFarmData.tenYearsOutput;
        result.capacityFactor = tempFarmData.tenYearsCapacity;
        tempPowerData = tempFarmData.tenYearsCount;
      }
      if (selectedTimePeriod.id === 11) {
        result.generationOutput = tempFarmData.maxOutput;
        result.capacityFactor = tempFarmData.maxCapacity;
        tempPowerData = tempFarmData.maxCount;
      }
    }
    if (selectedPeriodType?.id === 2) {
      const lastQuarter = moment().quarter() - 1;
      const quarterToMonthMap: GenericType = {
        1: "Jan - Mar",
        2: "Apr - Jun",
        3: "Jul - Sep",
        4: "Oct - Dec",
      };
      const quarterYear =
        lastQuarter === 1
          ? moment().subtract(1, "year").year()
          : moment().year();
      tempFarmData.data.forEach((item: GenericType) => {
        if (selectedTimePeriod.id === 1) {
          if (moment(item.day).month() === moment().month() - 1) {
            result.generationOutput = item.generationOutput;
            result.capacityFactor = item.capacityFactor;
            tempPowerData++;
          }
          helpingText.current = moment()
            .subtract(1, "months")
            .format("MMMM YYYY");
        }
        if (selectedTimePeriod.id === 2) {
          if (moment(item.day).quarter() === moment().quarter() - 1) {
            result.generationOutput = item.generationOutput;
            result.capacityFactor = item.capacityFactor;
            tempPowerData++;
          }
          helpingText.current =
            quarterToMonthMap[lastQuarter] + " " + quarterYear;
        }
        if (selectedTimePeriod.id === 3) {
          if (moment(item.day).year() === moment().year() - 1) {
            result.generationOutput = item.generationOutput;
            result.capacityFactor = item.capacityFactor;
            tempPowerData++;
          }
          helpingText.current = (moment().year() - 1).toString();
        }
      });
    }

    const end = moment().endOf("day").subtract(1, "day");
    let start = end
      .clone()
      .subtract(selectedTimePeriod.number, selectedTimePeriod.unit)
      .startOf("day");

    if (selectedTimePeriod.name === "YTD") {
      start.clone().startOf("year");
    }

    tempFarmData.data.forEach((item: GenericType) => {
      if (selectedTimePeriod.name === "Max") {
        result.minCap = Math.min(result.minCap, item.capacityFactor ?? 0);
        result.maxCap = Math.max(result.maxCap, item.capacityFactor ?? 0);
        start = moment.min(moment(start), moment(item.day));
      }
      if (moment(item.day).isBetween(start, end)) {
        result.minCap = Math.min(result.minCap, item.capacityFactor ?? 0);
        result.maxCap = Math.max(result.maxCap, item.capacityFactor ?? 0);
      }
    });
    result.powerCoverage =
      (tempPowerData * 100) /
      (end.diff(start, "days") * (tempFarmData.generationUnits.length ?? 1));
    return result;
  }, [
    powerDataFullProjectQuery.data,
    selectedProject,
    selectedTimePeriod,
    selectedPeriodType,
  ]);

  const getFarmDetailsQuery = useQuery({
    queryKey: ["farmDetails", selectedProject],
    enabled: !!selectedProject,
    placeholderData: null,
    queryFn: () => {
      if (!selectedProject) return null;
      return safelyCallApi(
        api.project.getProjectListInfo({
          windProjectIds: [selectedProject.id],
        }),
      )
        .then(({ status, data: resp }) => {
          if (status !== 200) {
            return null;
          }
          const data = resp[0];
          return {
            capacityMw: data.windProject.capacityMw ?? 0,
            operationDate: data.windProject.operationDate ?? null,
            numTurbines: data.windProject.numTurbines ?? 0,
            countryId: data.windProject.countryId ?? null,
            ratedPowerMw:
              data.windProject.turbineModels?.map(
                ({ ratedPowerMw }: GenericType) => ratedPowerMw,
              ) ?? [],
            turbineNames:
              data.windProject.turbineModels?.map(
                ({ name }: GenericType) => name,
              ) ?? [],
            turbineIds:
              data.windProject.turbineModels?.map(
                ({ id }: GenericType) => id,
              ) ?? [],
            developers:
              data.developers?.map(
                ({ companyName }: GenericType) => companyName,
              ) ?? [],
          };
        })
        .catch(() => {
          return null;
        });
    },
  });

  const turbineDetailsQuery = useQuery({
    queryKey: ["turbineDetails", getFarmDetailsQuery.data?.turbineIds],
    enabled: !!getFarmDetailsQuery.data?.turbineIds?.length,
    placeholderData: [],
    queryFn: ({ queryKey }) => {
      return safelyCallApi(
        api.turbine.getTurbines({
          ids: queryKey[1],
        }),
      )
        .then(({ status, data: resp }) => {
          if (status !== 200) {
            return null;
          }
          const res: GenericType = {};
          resp.forEach((item: GenericType) => {
            item.turbineModel?.suppliers?.forEach(
              ({ companyName }: GenericType) => {
                res[companyName] = 0;
              },
            );
          });
          return Object.keys(res);
        })
        .catch(() => {
          return [];
        });
    },
  });
  const countryDetailsQuery = useQuery({
    queryKey: ["countryDetails", getFarmDetailsQuery.data?.countryId],
    enabled: !!getFarmDetailsQuery.data?.countryId,
    placeholderData: null,
    queryFn: ({ queryKey }) => {
      return safelyCallApi(
        api.countries.getAllCountries({
          ids: [queryKey[1]],
        }),
      )
        .then(({ status, data: resp }) => {
          if (status !== 200) {
            return null;
          }
          return resp[0].name;
        })
        .catch(() => {
          return null;
        });
    },
  });

  return (
    <Box
      sx={{
        background: ({ palette }: GenericType) => palette.background.paper,
        p: 2,
        height: "22rem",
        borderRadius: 4,
        color: ({ palette }: GenericType) => palette.text.primary,
      }}
    >
      <Stack direction="column" spacing={2}>
        <Typography variant="h6">Key figures</Typography>
        <Stack direction="column" spacing={1}>
          <TextWithTooltipIcon
            iconSize="16px"
            gap="4px"
            label={<Typography variant="body2">Power data coverage</Typography>}
            tooltipText="The horizontal line illustrates the number of hours with registered power generation data for the wind farm compared to the total number of hours during the selected timeframe"
          />
          <ProgressBar value={Math.round(farmData?.powerCoverage)} />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            maxHeight: "16vh",
            mt: 10,
            justifyContent: "space-around",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={<Typography variant="body2">Operation date</Typography>}
                tooltipText="COD of the project. Note: The wind farm might have started power production before the official COD date"
              />
              {!getFarmDetailsQuery.isFetching ? (
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {moment(getFarmDetailsQuery.data?.operationDate).format(
                    "yyyy-MM-DD",
                  )}
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <Typography variant="body2">Country</Typography>
              {!countryDetailsQuery.isFetching ? (
                <Typography variant="h6">{countryDetailsQuery.data}</Typography>
              ) : (
                <CircularProgress />
              )}
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={
                  <Typography variant="body2">Installed capacity</Typography>
                }
                tooltipText="Initial installed capacity at COD"
              />
              {!getFarmDetailsQuery.isFetching ? (
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {getFarmDetailsQuery.data?.capacityMw} MW
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={
                  <Typography variant="body2">Power generation</Typography>
                }
                tooltipText="Total power generation during the selected timeframe"
              />
              <Typography variant="h6">
                {Math.round(farmData?.generationOutput)} GWh
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              height: "16vh",
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={
                  <Typography variant="body2">Number of turbines</Typography>
                }
                tooltipText="Initial number of turbines at COD"
              />
              {!getFarmDetailsQuery.isFetching ? (
                <Typography
                  variant="h6"
                  sx={{
                    height: "8vh",
                  }}
                >
                  {getFarmDetailsQuery.data?.numTurbines}
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={<Typography variant="body2">Capacity factor</Typography>}
                tooltipText="Daily-average capacity factor during the selected timeframe, based on initial installed capacity"
              />
              <Typography variant="h6">
                {Math.round(farmData?.capacityFactor)} %
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              height: "16vh",
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", height: "16vh", alignItems: "center" }}
            >
              <Typography variant="body2">Turbine nominal power</Typography>
              {!getFarmDetailsQuery.isFetching ? (
                <Box
                  sx={{
                    overflowY: "auto",
                    height: "8vh",
                    maxHeight: "8vh",
                  }}
                >
                  {getFarmDetailsQuery.data?.ratedPowerMw?.map(
                    (power: number) => {
                      return (
                        <Typography key={power} variant="h6">
                          {power} MW
                        </Typography>
                      );
                    },
                  )}
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              {selectedPeriodType?.id === 2 && (
                <Typography variant="body2">{helpingText.current}</Typography>
              )}
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={
                  <Typography variant="body2">Max. capacity factor</Typography>
                }
                tooltipText="Maximum daily-average capacity factor during the selected timeframe, based on initial installed capacity"
              />
              <Typography variant="h6">
                {Math.round(farmData?.maxCap)} %
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <Typography variant="body2">Turbine supplier</Typography>
              {!turbineDetailsQuery.isFetching ? (
                <Box
                  sx={{
                    overflowY: "auto",
                    height: "8vh",
                    maxHeight: "8vh",
                  }}
                >
                  {turbineDetailsQuery.data?.map((supplier: string) => {
                    return (
                      <Typography
                        alignSelf="center"
                        sx={{
                          textOverflow: "ellipsis",
                          maxWidth: "7vw",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        title={supplier}
                        key={supplier}
                        variant="h6"
                      >
                        {supplier}
                      </Typography>
                    );
                  })}
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <TextWithTooltipIcon
                iconSize="16px"
                gap="4px"
                label={
                  <Typography variant="body2">Min. capacity factor</Typography>
                }
                tooltipText="Minimum daily-average capacity factor during the selected timeframe, based on initial installed capacity"
              />
              <Typography variant="h6">
                {Math.round(farmData?.minCap)} %
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="column"
            spacing={2}
            sx={{
              maxHeight: "16vh",
              mt: 10,
              justifyContent: "space-between",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <Stack direction="row" alignSelf="center" spacing={0.5}>
                <Typography variant="body2">Turbine model</Typography>
              </Stack>
              {!getFarmDetailsQuery.isFetching ? (
                <Box
                  sx={{
                    overflowY: "auto",
                    height: "8vh",
                    maxHeight: "8vh",
                  }}
                >
                  {getFarmDetailsQuery.data?.turbineNames?.map(
                    (model: string) => {
                      return (
                        <Typography key={model} variant="h6">
                          {model}
                        </Typography>
                      );
                    },
                  )}
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Stack>
            <Stack
              direction="column"
              sx={{ textAlign: "center", alignItems: "center" }}
            >
              <Typography variant="body2">Developer(s)</Typography>
              {!getFarmDetailsQuery.isFetching ? (
                <Box
                  sx={{
                    overflowY: "auto",
                    maxHeight: "8vh",
                  }}
                >
                  {getFarmDetailsQuery.data?.developers.map((dev: string) => {
                    return (
                      <Typography
                        key={dev}
                        variant="h6"
                        alignSelf="center"
                        sx={{
                          textOverflow: "ellipsis",
                          maxWidth: "7vw",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {dev}
                      </Typography>
                    );
                  })}
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
