import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { api } from "../../api/api";
import { safelyCallApi } from "../../helper/requestWrapper";
import { GenericType, WindProject } from "../../types";

export const useLookupQueries = () => {
  const queryClient = useQueryClient(); // Access queryClient instance

  const lookupProjectsQuery = useQuery({
    queryKey: ["lookupProjects"],
    placeholderData: [],
    queryFn: () => {
      return safelyCallApi(api.lookup.getProjects())
        .then(({ status, data }) => {
          if (status !== 200) {
            return [];
          }
          return data;
        })
        .catch(() => []);
    },
  });

  const lookupProjectsObject = useMemo(() => {
    if (lookupProjectsQuery.isFetching) return null;
    const res: GenericType = {};
    lookupProjectsQuery.data.forEach((project: WindProject) => {
      res[project.windProjectId] = { ...project };
    });
    return res;
  }, [lookupProjectsQuery.data, lookupProjectsQuery.isFetching]);

  const resetLookupData = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["lookupProjects"] });
  }, [queryClient]);

  return {
    lookupProjectsQuery,
    lookupProjectsObject,
    resetLookupData,
  };
};
