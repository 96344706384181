import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, getEsgianTheme, Stack } from "@esgian/esgianui";
import {
  Chart,
  ChartOptions,
  ChartTypeRegistry,
  CoreScaleOptions,
  Plugin,
  Scale,
  TooltipModel,
} from "chart.js";
import moment from "moment";

import { getThemeMode } from "../../store/selector/common";
import { FONT_FAMILY, GenericType, THEME } from "../../types";
import {
  ChartData as ChartDataType,
  ChartTimeSeriesDataSet,
  TimeSeriesData,
} from "../../types/charts";

import { defaultTimeSeriesTooltip } from "./tooltips/TimeseriesTooltip";
import CanvasLegends from "./CanvasLegends";
import Chartjs from "./ChartJs";

type Series = {
  label: string;
  data: TimeSeriesData[];
};
type Prop = {
  series: Series[];
  plugins?: Plugin<keyof ChartTypeRegistry, unknown>[]; // Ensure the plugins are typed correctly
  type: "line" | "bar";
  loading?: boolean;
  id: string;
  customLabelRender?:
    | ((
        this: Scale<CoreScaleOptions>,
        tickValue: string | number,
        index: number,
      ) => string | number | null | undefined)
    | undefined;
  chartRef: React.RefObject<Chart | undefined>; // Reference to Chart.js instance
};

const getOptions = (
  theme: GenericType,
  data: ChartDataType,
  maxYaxis: number,
  customLabelRender:
    | ((
        this: Scale<CoreScaleOptions>,
        tickValue: string | number,
        index: number,
      ) => string | number | null | undefined)
    | undefined = undefined,
): ChartOptions => {
  const {
    palette: {
      text: { primary },
      neutral: { neutral01 },
    },
  } = theme;

  const categories: string[] = (data?.labels ?? []) as string[];

  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      x: {
        min: 0,
        offset: true,
        type: "category",
        labels: categories,
        ticks: {
          callback: customLabelRender
            ? customLabelRender
            : function (
                this: Scale<CoreScaleOptions>,
                tickValue: string | number,
                index: number,
              ): string | number | null | undefined {
                const currentDate = moment(this.getLabelForValue(index));

                if (index >= categories.length) {
                  currentDate.format("MMM DD");
                }
                const nextDate = moment(this.getLabelForValue(index + 1));
                let rollup = "hour";
                if (nextDate.diff(currentDate, "hours") < 1) {
                  rollup = "min";
                }

                if (currentDate.clone().hour() === 0 && rollup === "hour") {
                  return currentDate.format("MMM DD");
                }
                if (
                  currentDate.clone().startOf("day").isSame(currentDate) &&
                  rollup === "min"
                ) {
                  return currentDate.format("MMM DD");
                }
                return currentDate.format("YYYY:MM:DD");
              },
          color: primary,
          maxRotation: 45,
          minRotation: 45,
          font: {
            size: 10,
            family: FONT_FAMILY,
          },
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          tickLength: 0,
          color: `${neutral01}7F`,
        },
        afterFit: function (axis: Scale<CoreScaleOptions>): void {
          const stepSize = `${Math.round((maxYaxis * 1.5) / 5) * 4}`.length;
          const size = stepSize < 4 ? 4 : stepSize;

          // Ensure 'axis' has a 'width' property; use a type assertion if necessary
          (axis as unknown as { width: number }).width = size * 8; // Safely set the width
        },
        ticks: {
          color: primary,
          font: {
            size: 10,
            family: ["Roboto", "helvetica", "Arial", "sans-serif"].join(","),
          },
          callback: (val: string | number): string | number =>
            maxYaxis >= 5 ? parseInt(`${val}`) : val,
          maxTicksLimit: 6, // +1 for zero
          stepSize: Math.round(maxYaxis / 5),
          count: 6,
          precision: 2,
          autoSkip: false,
        },
        min: 0,
        max: maxYaxis,
        type: "linear",
        position: "left",
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 0,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {},
        enabled: false,
        position: "nearest",
        external: (context: {
          chart: Chart;
          tooltip: TooltipModel<keyof ChartTypeRegistry>;
        }) => defaultTimeSeriesTooltip(context, data, theme.palette),
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };
};

function CanvasTimeSeriesChart({
  customLabelRender,
  chartRef,
  plugins,
  series,
  type,
  loading,
  id,
}: Prop) {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const {
    palette: {
      charts: { tenColors },
    },
  } = theme;

  const data = useMemo(() => {
    if (!series.length) return { labels: [], datasets: [] };
    const labels: Record<string, null> = {}; // Explicitly typing labels

    // Collect unique timestamps
    series?.forEach(({ data: sData }) => {
      sData.forEach(({ x }: TimeSeriesData) => {
        labels[`${x}`] = null; // Store the timestamp as a key
      });
    });

    // Convert keys to numbers, sort, and then return them as strings
    const sortedLabels = Object.keys(labels)
      .map((key) => parseInt(key, 10)) // Convert keys to numbers
      .sort((a, b) => a - b) // Sort the numbers
      .map((timestamp) => timestamp);
    return {
      labels: sortedLabels,
      datasets:
        series?.map(
          (val: GenericType, i: number): ChartTimeSeriesDataSet => ({
            borderColor: tenColors[i % tenColors.length], // Use modulo to avoid index out of bounds
            borderWidth: 2,
            type: "line",
            spanGaps: true,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            tension: 0,
            ...val,
            originalBackgroundColor: val.backgroundColor,
            label: val.label ?? "", // Ensure each dataset has a label
            data: val.data ?? [], // Pass data correctly
          }),
        ) ?? [],
    };
  }, [series, tenColors]);

  const options = useMemo(() => {
    if (!data.datasets.length) return null;
    const maxYaxis = Math.max(
      ...(series.flatMap((s) => s.data.map((d) => d.y)) ?? 100),
    );
    return getOptions(theme, data, maxYaxis, customLabelRender);
  }, [customLabelRender, data, series, theme]);

  return (
    <Stack sx={{ width: "auto" }} spacing={1}>
      <CanvasLegends data={data} chartRef={chartRef} id={id} />
      <Box sx={{ height: "35vh", width: "100%", position: "relative" }}>
        <Chartjs
          plugins={plugins}
          chartRef={chartRef}
          loading={loading}
          id={id}
          type={type}
          data={data}
          options={options ?? undefined}
        />
      </Box>
    </Stack>
  );
}

export default CanvasTimeSeriesChart;
