import { useState } from "react";
import { Box } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { PowerOutputProjectListWindFarm } from "../../components/PowerOutputProjectListWindFarm";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import { GenericType, PowerOutputEnum } from "../../types";

export const PowerOutput = () => {
  const { powerDataQuery } = usePowerGenQueries();
  const [selectedView, setSelectedView] = useState("Capacity");
  return (
    <Box>
      {!powerDataQuery.isFetching ? (
        <PowerOutputProjectListWindFarm
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          tab={PowerOutputEnum.WindFarm}
          tableData={powerDataQuery.data as GenericType}
        />
      ) : (
        <Loading />
      )}
    </Box>
  );
};
