import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, FormControl } from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { usePowerGenQueries } from "../../hooks/useQueries/usePowerGenQueries";
import {
  getPowerOutputDisabledTime,
  getPowerOutputPeriodType,
  getPowerOutputTimePeriod,
  getPowerOutputWindProject,
} from "../../store/selector/powerOutput";
import {
  POWER_GEN_FILTERS,
  setCompareWindProjects,
  setPowerGenPeriodType,
  setPowerGenTimePeriod,
  setPowerGenWindProject,
} from "../../store/slice/powerOutput";
import { GenericType, SelectedFilterItem } from "../../types";
import { StyledAutocomplete } from "../StyledAutocomplete";

const useStyles = () => {
  return {
    secondRow: {
      display: "flex",
      mt: 2,
    },
    formControl: {
      width: 340,
      "& .MuiSelect-select": {
        height: "38px !important",
        lineHeight: "36px",
      },
      mr: 2,
    },
    periodBox: {
      ml: 2,
    },
    filterDate: {
      "& .MuiFormControl-root": {
        width: 340,
      },
    },
  };
};

export const PowerOutputHeader = () => {
  const { powerDataQuery } = usePowerGenQueries();
  const dispatch = useDispatch();
  const selectedProject = useSelector(getPowerOutputWindProject);
  const selectedPeriodType = useSelector(getPowerOutputPeriodType);
  const selectedTimePeriod = useSelector(getPowerOutputTimePeriod);
  const disabledTimeOption = useSelector(getPowerOutputDisabledTime);
  const classes = useStyles();

  const timePeriodOptions = useMemo(() => {
    if (selectedPeriodType?.id === 1) {
      return POWER_GEN_FILTERS.PERIOD_OPTIONS;
    }
    return POWER_GEN_FILTERS.CALENDAR_OPTIONS;
  }, [selectedPeriodType]);

  const projectOptions = useMemo((): GenericType[] => {
    if (!powerDataQuery.data) return [];
    return Object.values(powerDataQuery.data);
  }, [powerDataQuery]);

  return (
    <Box>
      <Box sx={classes.secondRow}>
        <FormControl sx={classes.formControl}>
          <StyledAutocomplete
            loading={projectOptions.length === 0}
            disableClearable={true}
            disableCloseOnSelect={false}
            options={projectOptions}
            selectedItem={
              selectedProject ? (selectedProject as SelectedFilterItem) : null
            }
            setSelectedItem={(value: GenericType) => {
              dispatch(
                setPowerGenWindProject({
                  id: value.id,
                  name: value.name,
                  sourceName: value.sourceName,
                }),
              );
              dispatch(setCompareWindProjects([]));
            }}
            labelKey="name"
            multipleSelection={false}
            size="medium"
            placeholder="Wind farm"
            maxHeight="44vh"
            inputLabelText="Wind farm"
          />
        </FormControl>
        <FormControl sx={classes.formControl}>
          <StyledAutocomplete
            disableClearable={true}
            disableCloseOnSelect={false}
            options={POWER_GEN_FILTERS.PERIOD_TYPE_OPTIONS}
            selectedItem={selectedPeriodType as unknown as SelectedFilterItem}
            setSelectedItem={(value: GenericType) => {
              dispatch(
                setPowerGenPeriodType({ id: value.id, name: value.name }),
              );
            }}
            labelKey="name"
            multipleSelection={false}
            size="medium"
            placeholder="Time type"
            maxHeight="44vh"
            disabledOption={{
              id: disabledTimeOption?.id ?? 0,
              name: disabledTimeOption?.name ?? "",
            }}
            inputLabelText="Time type"
          />
        </FormControl>
        <FormControl sx={classes.formControl}>
          <StyledAutocomplete
            disableClearable={true}
            disableCloseOnSelect={false}
            options={timePeriodOptions}
            selectedItem={selectedTimePeriod as unknown as SelectedFilterItem}
            setSelectedItem={(value: GenericType) => {
              dispatch(
                setPowerGenTimePeriod({
                  number: value.number,
                  unit: value.unit,
                  id: value.id,
                  name: value.name,
                }),
              );
            }}
            labelKey="name"
            multipleSelection={false}
            size="medium"
            placeholder="Time period"
            maxHeight="44vh"
            inputLabelText="Time period"
          />
        </FormControl>
      </Box>
    </Box>
  );
};
