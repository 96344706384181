import moment from "moment";

import { RootState } from "../../types";

export const getSortingInfo = (state: RootState) => {
  return state.powerOutput.tableSortOrder;
};
export const getPowerOutputWindProject = (state: RootState) => {
  return state.powerOutput.selectedWindProject;
};
export const getPowerOutputCompareWindProjects = (state: RootState) => {
  return state.powerOutput.compareWindProjects;
};
export const getPowerOutputPeriodType = (state: RootState) => {
  return state.powerOutput.periodType;
};

export const getPowerOutputPeriodStartDate = (state: RootState) => {
  if (state.powerOutput.periodType?.id === 1) {
    if (state.powerOutput.timePeriod?.name === "YTD") {
      return moment().startOf("year");
    }
    if (state.powerOutput.timePeriod?.name === "Max") {
      return moment().subtract(20, "year");
    }
    const { number = 20, unit = "year" } = state.powerOutput.timePeriod || {};

    return moment().subtract(number, unit);
  }
  return moment().subtract(20, "year");
};
export const getPowerOutputTimePeriod = (state: RootState) => {
  return state.powerOutput.timePeriod;
};

export const getPowerOutputDisabledTime = (state: RootState) => {
  return state.powerOutput.disabledOption;
};
